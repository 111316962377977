import React from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="dt-footer text">
      <span>
        {capitalize(t('auth.headers.copyright', 'copyright'))}
        {' '}
        54Origins
      </span>
      <span className="px-1">&#169;</span>
      <span>{new Date().getFullYear()}</span>
      <span className="ml-5">Version: 5.12.7.17</span>
    </footer>
  );
}

export default Footer;
