import PropTypes from 'prop-types';
import React, {
  memo, useCallback, useContext, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col, Form, Row,
} from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { mdiClose, mdiContentSaveOutline } from '@mdi/js';

import { ApiContext } from '../api/ApiContextProvider';
import { getProfileInfo, isProfileFetching } from '../profile/selectors';
import useCustomState from '../hooks/useCustomState';
import { antNotification, capitalize } from '../mainUtils';

import ActorFormWrapper from '../actors/components/infoForms/ActorFormWrapper';
import UserInfoFormItems from '../actors/components/infoForms/UserInfoFormItems';
import AntButtonWithMDI from './AntButtonWithMDI';


const rules = {
  first_name: [{ max: 200 }, { required: true }],
  last_name: [{ max: 200 }, { required: true }],
  email: [{ type: 'email' }, { required: true }],
  password: [{ min: 4 }, { max: 200 }],
  new_password: [
    { min: 6 },
    { max: 200 },
  ],
  password_confirmation: '',
  initial_key: [{ len: 130 }],
};

function ProfileEditFormComponent({
  setToggle,
  updateActorCallback,
}) {
  const { t } = useTranslation();
  const [actorForm] = Form.useForm();

  const {
    requestUpdateProfile,
    requestUpdateUserPassword,
  } = useContext(ApiContext);

  const { actor: profileInfo } = useSelector(getProfileInfo);
  const profileFetching = useSelector(isProfileFetching);

  const {
    uuid: actorUUID,
    actor_type: actorType,
    uinfo: {
      birthday = null,
      email = '',
      first_name = '',
      last_name = '',
      login = '',
      group_names = [],
      phone_number = '',
    } = {},
  } = profileInfo || {};

  const {
    formIsEdit,
    changeState,
  } = useCustomState({
    formIsEdit: false,
  });

  const profileInitialValues = {
    birthday: birthday ? moment(birthday, 'YYYY-MM-DD') : null,
    email,
    first_name,
    last_name,
    login,
  };

  const tMsg = (
    path,
    defaultValue,
  ) => capitalize(t(`auth.validation.messages.${path}`, defaultValue || path));

  const validateMessages = {
    required: tMsg('required', 'is required'),
    string: {
      len: tMsg('len', 'must be exactly ${len} characters'),
      min: tMsg('min', 'must be at least ${min} characters'),
      max: tMsg('max', 'cannot be longer than ${max} characters'),
    },
    types: {
      email: tMsg('email', 'is not a valid email'),
    },
  };

  const cancelOnClick = useCallback(() => {
    setToggle();
    actorForm.resetFields();
  }, []);

  const onSubmitFunc = useCallback(async () => {
    const changedInputs = actorForm.getFieldsValue(null, (meta) => meta.touched);

    const {
      birthday,
      email,
      first_name,
      last_name,
      password,
      login,
      old_password,
      password_confirmation,
      typeOfUser: actor_type,
    } = changedInputs;

    try {
      await actorForm.validateFields();

      const data = {
        ...profileInfo.uinfo,
        ...(first_name ? { first_name } : {}),
        ...(last_name ? { last_name } : {}),
        ...(email ? { email } : {}),
        ...(login ? { login } : {}),
        ...(birthday ? { birthday: moment(birthday).format('YYYY-MM-DD') } : {}),
      };

      delete data?.groups;
      delete data?.password;
      // delete data?.phone_number

      if (birthday) {
        data.birthday = moment(birthday).format('YYYY-MM-DD');
      }

      requestUpdateProfile(data).then(() => {
        antNotification.defaultSuccess();

        updateActorCallback?.();
      });

      if (password && old_password && password_confirmation) {
        requestUpdateUserPassword({
          password,
          old_password,
          password_confirmation,
        }).then(() => {
          antNotification.success(capitalize(t(
            'auth.notifications.updated_successfully',
            'password successfully updated',
          )));
          actorForm.resetFields(['password', 'old_password', 'password_confirmation']);
        });
      }

      setToggle();
    } catch (e) {
      antNotification.error(capitalize(t('auth.notifications.invalid_data', 'invalid data entered')));

      cancelOnClick();
      actorForm.setFieldsValue(profileInitialValues);
    }
  }, []);

  const onFieldsChange = () => {
    changeState({ formIsEdit: true });
  };

  return (
    <ActorFormWrapper
      actorForm={actorForm}
      validateMessages={validateMessages}
      initialValues={profileInitialValues}
      onFieldsChange={onFieldsChange}
    >
      <Row>
        <Col span={24} className="d-flex">
          <AntButtonWithMDI
            className="button-primary ml-auto"
            size="small"
            pathForMdi={mdiContentSaveOutline}
            onClick={onSubmitFunc}
            disabled={!formIsEdit || profileFetching}
            label={capitalize(t('auth.buttons.save', 'save'))}
          />
          <AntButtonWithMDI
            className="ml-2 button-secondary-outlined"
            size="small"
            onClick={cancelOnClick}
            pathForMdi={mdiClose}
            label={capitalize(t('auth.buttons.cancel', 'cancel'))}
          />
        </Col>
      </Row>
      <UserInfoFormItems
        hideElements={['actorsModal', 'phone']}
        actorData={profileInfo}
        actorForm={actorForm}
        actorType={actorType}
        actorUUID={actorUUID}
        editMode
        isProfile
        rules={rules}
        validateMessages={validateMessages}
      />
    </ActorFormWrapper>
  );
}

ProfileEditFormComponent.propTypes = {
  setToggle: PropTypes.func,
  updateActorCallback: PropTypes.func,
};

export default memo(ProfileEditFormComponent);
