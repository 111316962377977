// THIS IS NOT GLOBAL REACT COMPONENT FROM ECOSYSTEM54.
// UPDATED 2023.01.24 //Format YYYY|MM|DD

import PropTypes from 'prop-types';
import React, { useId, useState } from 'react';
import {
  Button, Col, Popconfirm, Row, Tooltip,
} from 'antd';
import Icon from '@mdi/react';
import { antNotification } from '../mainUtils';

function BtnSendRequestAndShowResult({
  action,
  btnLabel,
  btnOutline = false,
  btnType = 'primary',
  className,
  disabledBtn,
  errorMsgNearBtn,
  finallyActionCallback,
  iconBeforeLabel = false,
  iconSize = 1,
  pathForIcon,
  popConfirmTitle,
  requestWithoutAnyFurtherAction = false,
  responseMsgAsBtnLabel,
  showErrorAsBtnLabel,
  sliceError,
  successActionCallback,
  successMsgNearBtn,
  tooltipPlacement = 'right',
  withPopConfirm = false,
}) {
  const id = useId();

  const [fetching, setFetching] = useState();
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [openPopConfirm, setOpenPopConfirm] = useState(false);

  const sendRequest = () => {
    setFetching(true);

    action?.()
      .then((msg) => {
        if (!requestWithoutAnyFurtherAction) {
          const { message } = msg || {};

          if (message) {
            setSuccess(message);
            antNotification.success(message);
          } else {
            antNotification.defaultSuccess();
          }
          successActionCallback?.();
        }
      })
      .catch((err) => {
        if (!requestWithoutAnyFurtherAction) {
          const { message } = err;

          if (message) {
            setError(message);
          }
        }
      })
      .finally(() => {
        setFetching(false);

        finallyActionCallback?.();
      });
  };

  const btnOnClick = () => {
    if (withPopConfirm) {
      setOpenPopConfirm(true);
    } else {
      sendRequest();
    }
  };

  const onConfirm = () => {
    sendRequest();
    setOpenPopConfirm(false);
  };

  const onCancel = () => {
    setOpenPopConfirm(false);
  };

  const getFormattedError = sliceError ? `${error?.slice(0, 20)}...` : error;

  const responseMessageNearBtn = errorMsgNearBtn || successMsgNearBtn;
  const responseMessage = error || success;

  const checkIsError = (param) => error && param;
  const checkIsSuccess = (param) => success && param;

  const getIcon = (
    <Col flex="auto">
      <Icon
        className={`mb-1 ${iconBeforeLabel ? 'mr-2' : 'ml-2'}`}
        path={pathForIcon}
        size={iconSize}
      />
    </Col>
  );

  return (
    <Row gutter={16} className={className}>
      <Col span={responseMessageNearBtn ? 12 : 24}>
        <Tooltip
          title={responseMsgAsBtnLabel && responseMessage}
          placement={tooltipPlacement}
          color={error ? 'red' : 'green'}
          key={`tooltip${id}`}
        >
          <Popconfirm
            open={openPopConfirm}
            title={popConfirmTitle || 'Are you sure？'}
            okText="Yes"
            cancelText="No"
            onConfirm={onConfirm}
            onCancel={onCancel}
          >
            <Button
              className={btnOutline && `button-${checkIsError('danger') || 'primary'}-outlined`}
              onClick={btnOnClick}
              disabled={fetching || disabledBtn}
              type={btnType}
            >
              <Row>
                {pathForIcon && iconBeforeLabel && getIcon}
                <Col flex="auto">
                  {fetching ? 'Pending...' : (
                    <>
                      {error && showErrorAsBtnLabel ? getFormattedError : btnLabel}
                    </>
                  )}
                </Col>
                {pathForIcon && !iconBeforeLabel && getIcon}
              </Row>
            </Button>
          </Popconfirm>

        </Tooltip>
      </Col>
      {responseMessageNearBtn && !responseMsgAsBtnLabel && (
      <Col span={12}>
        {errorMsgNearBtn && <span style={{ color: 'red' }}>{error}</span>}
        {successMsgNearBtn && <span style={{ color: 'green' }}>{success}</span>}
      </Col>
      )}
    </Row>
  );
}

export default BtnSendRequestAndShowResult;

BtnSendRequestAndShowResult.propTypes = {
  action: PropTypes.func,
  btnLabel: PropTypes.string,
  btnOutline: PropTypes.bool,
  btnType: PropTypes.string,
  className: PropTypes.string,
  disabledBtn: PropTypes.any,
  errorMsgNearBtn: PropTypes.bool,
  iconBeforeLabel: PropTypes.bool,
  iconSize: PropTypes.number,
  pathForIcon: PropTypes.any,
  requestWithoutAnyFurtherAction: PropTypes.bool,
  responseMsgAsBtnLabel: PropTypes.any,
  showErrorAsBtnLabel: PropTypes.bool,
  sliceError: PropTypes.bool,
  successActionCallback: PropTypes.any,
  successMsgNearBtn: PropTypes.bool,
  tooltipPlacement: PropTypes.string,
};
