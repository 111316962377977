import { isNil } from 'lodash';
import { ActorsConstants } from '../constants/actionTypes';

const initialState = {
  actors: [],
  fetching: false,
  fetchingDefaultGroups: false,
  fetchingGroups: false,
  fetchingServices: false,
  fetchingUsers: false,
  groups: [],
  defaultGroups: [],
  groupsMap: new Map(),
  defaultGroupsMap: new Map(),
  services: [],
  servicesMap: new Map(),
  users: [],
  usersMap: new Map(),
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // GET ACTOR LIST
    case ActorsConstants.GET_ALL_ACTORS_REQUEST: {
      return {
        ...state,
        fetching: true,
      };
    }

    case ActorsConstants.GET_ALL_ACTORS_SUCCESS: {
      const { actors = [] } = payload;
      const checkActors = isNil(actors) ? [] : actors;

      const actorsData = checkActors.reduce((acc, item) => ({
        ...acc,
        [item.actor_type]: [...acc[item.actor_type], item],
      }), {
        user: [],
        classic_user: [],
        group: [],
        service: [],
      });

      const groupsMap = new Map([
        ...actorsData.group.map((item) => [item.uuid, item]),
      ]);

      const usersMap = new Map([
        ...[...actorsData.user, ...actorsData.classic_user].map((item) => [item.uuid, item]),
      ]);

      const servicesMap = new Map([
        ...actorsData.service.map((item) => [item.uuid, item]),
      ]);

      return {
        ...state,
        fetching: false,
        actors: checkActors,
        // groups: actorsData.group,
        users: [...actorsData.user, ...actorsData.classic_user],
        // services: actorsData.service,
        groupsMap,
        usersMap,
        servicesMap,
      };
    }

    case ActorsConstants.GET_ALL_ACTORS_FAILURE: {
      return {
        ...state,
        fetching: false,
      };
    }

    // GET ALL GROUPS
    case ActorsConstants.GET_ALL_GROUPS_REQUEST: {
      return {
        ...state,
        fetchingGroups: true,
      };
    }

    case ActorsConstants.GET_ALL_GROUPS_SUCCESS: {
      const { actors = [] } = payload;

      const groupsMap = new Map([
        ...actors.map((item) => [item.uuid, item]),
      ]);

      return {
        ...state,
        fetchingGroups: false,
        groups: actors,
        groupsMap,
      };
    }

    case ActorsConstants.GET_ALL_GROUPS_FAILURE:
      return {
        ...state,
        fetchingGroups: false,
      };

      // GET DEFAULT GROUPS

    case ActorsConstants.GET_DEFAULT_GROUPS_REQUEST: {
      return {
        ...state,
        fetchingDefaultGroups: true,
      };
    }

    case ActorsConstants.GET_DEFAULT_GROUPS_SUCCESS: {
      console.log('payload', payload);
      const { actors = [] } = payload || {};

      const defaultGroupsMap = new Map([
        ...actors.map((item) => [item.uuid, item]),
      ]);

      return {
        ...state,
        fetchingDefaultGroups: false,
        defaultGroups: actors,
        defaultGroupsMap,
      };
    }

    case ActorsConstants.GET_DEFAULT_GROUPS_FAILURE: {
      return {
        ...state,
        fetchingDefaultGroups: false,
      };
    }

    // GET ALL USERS
    case ActorsConstants.GET_ALL_USERS_REQUEST: {
      return {
        ...state,
        fetchingUsers: true,
      };
    }

    case ActorsConstants.GET_ALL_USERS_SUCCESS: {
      const { actors = [] } = payload || {};

      const usersMap = new Map([
        ...actors.map((item) => [item.uuid, item]),
      ]);

      return {
        ...state,
        fetchingUsers: false,
        users: actors,
        usersMap,
      };
    }

    case ActorsConstants.GET_ALL_USERS_FAILURE: {
      return {
        ...state,
        fetchingUsers: false,
      };
    }

    // GET ALL SERVICES
    case ActorsConstants.GET_ALL_SERVICES_REQUEST: {
      return {
        ...state,
        fetchingServices: true,
      };
    }

    case ActorsConstants.GET_ALL_SERVICES_SUCCESS: {
      const { actors = [] } = payload;

      const servicesMap = new Map([
        ...actors.map((item) => [item.uuid, item]),
      ]);

      return {
        ...state,
        fetchingServices: false,
        services: actors,
        servicesMap,
      };
    }

    case ActorsConstants.GET_ALL_SERVICES_FAILURE: {
      return {
        ...state,
        fetchingServices: false,
      };
    }

    // REMOVE ACTOR
    case ActorsConstants.REMOVE_ACTOR_REQUEST: {
      return {
        ...state,
        fetching: true,
      };
    }

    case ActorsConstants.REMOVE_ACTOR_SUCCESS: {
      const { uuid } = payload.actor;
      const {
        actors, groupsMap, usersMap, servicesMap,
      } = state;

      let actorsType;

      switch (true) {
        case groupsMap.has(uuid):
          actorsType = 'groups';
          break;
        case usersMap.has(uuid):
          actorsType = 'users';
          break;
        case servicesMap.has(uuid):
          actorsType = 'services';
          break;
        default:
          break;
      }

      return {
        ...state,
        fetching: false,
        actors: actors.filter((item) => item.uuid !== uuid),
        [actorsType]: state[actorsType].filter((item) => item.uuid !== uuid),
      };
    }

    case ActorsConstants.REMOVE_ACTOR_FAILURE: {
      return {
        ...state,
        fetching: false,
      };
    }

    case ActorsConstants.REMOVE_ACTOR_CANCEL: {
      return {
        ...state,
        fetching: false,
      };
    }

    case ActorsConstants.CREATE_ACTOR_SUCCESS: {
      const { actor } = payload;
      const { actors } = state;

      let actorsType;

      switch (actor.actor_type) {
        case 'group':
          actorsType = 'groups';
          break;
        case 'user':
        case 'classic_user':
          actorsType = 'users';
          break;
        case 'service':
          actorsType = 'services';
          break;
        default:
          break;
      }

      const actorsTypeMap = new Map(state[`${actorsType}Map`]);
      actorsTypeMap.set(actor.uuid, actor);

      return {
        ...state,
        actors: [...actors, actor],
        [actorsType]: [...state[actorsType], actor],
        [`${actorsType}Map`]: actorsTypeMap,
      };
    }

    default:
      return state;
  }
};
