import {
  mdiClose,
  mdiContentCopy,
  mdiContentSaveOutline,
  mdiInformationOutline,
  mdiSquareEditOutline,
  mdiTrashCanOutline,
  mdiTune,
  mdiFilterOutline,
} from '@mdi/js';
import Icon from '@mdi/react';
import {
  Button,
  Card,
  Col,
  Collapse,
  Form,
  Row,
  Skeleton,
  Space,
  Spin,
  Tag,
} from 'antd';
import { isString } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import DeleteButton from '../../components/DeleteButton';
import GroupInfoFormItems from './infoForms/GroupInfoFormItems';
import ServiceInfoFormItems from './infoForms/ServiceInfoFormItems';
import UserInfoFormItems from './infoForms/UserInfoFormItems';
import SyncDashboardBtnModal from './syncDashboard/SyncDashboardBtnModal';
import DateLabelFormat54origins from '../../54origins/components/DateLabelFormat54origins';
import AntButtonWithMDI from '../../components/AntButtonWithMDI';

import { ApiContext } from '../../api/ApiContextProvider';
import {
  antNotification,
  textToClipboard,
  paramsURL,
  capitalizeAndTranslateMsg,
} from '../../mainUtils';
import {
  isActorsFetching,
  isInfoFetching,
} from '../selectors';
import { getNameForActor } from '../../54origins/utils54origins';

function ActorInfo({
  actorURLUUID,
  actorType,
  onSuccessUpdate,
  onSuccessDelete,
  switchView,
  actorData,
  showPerms,
  applyFilter,
}) {
  const [actorForm] = Form.useForm();
  const {
    requestUpdateActor,
    requestGetActor,
    downLoadBackupFileRequest,
  } = useContext(ApiContext);

  const dataFromUrl = useSelector(paramsURL.get);
  const isFetching = useSelector(isInfoFetching);
  const isActorListFetching = useSelector(isActorsFetching);

  const [editMode, setEditMode] = useState(false);
  const [fieldsIsChanged, setFieldsIsChanged] = useState(false);

  const [collapsed, setCollapsed] = useReducer((state) => !state, false);

  const {
    initial_key = '',
    root_perms_signature: rootPermsSignature = false,
    uuid: actorUUID,
    created,
    uinfo: {
      birthday = null,
      comment = '',
      description = '',
      email = '',
      first_name = '',
      groups = [],
      last_name = '',
      listing_group = '',
      login = '',
      // password = '',
      phone_number = '',
      group_name = '',
      weight = '',
      service_domain = '',
      service_name = '',
    } = {},
  } = actorData || {};

  const serviceInitialValues = {
    comment,
    description,
    groups,
    initial_key,
    listing_group,
    service_domain,
    service_name,
  };

  const userInitialValues = {
    birthday: birthday ? moment(birthday, 'YYYY-MM-DD') : null,
    comment,
    email,
    first_name,
    groups,
    initial_key,
    last_name,
    login,
    // password,
    phone_number,
  };

  const groupInitialValues = {
    group_name,
    weight,
    description,
    comment,
  };

  const filterActive = isString(dataFromUrl.groups)
    && dataFromUrl.groups === actorURLUUID
    && !dataFromUrl.actor_type;

  const defaultTranslatePath = 'auth.validation.messages.';

  const validateMessages = {
    required: capitalizeAndTranslateMsg('required', 'is required'),
    string: {
      len: capitalizeAndTranslateMsg(`${defaultTranslatePath}len`, 'must be exactly ${len} characters'),
      min: capitalizeAndTranslateMsg(`${defaultTranslatePath}min`, 'must be at least ${min} characters'),
      max: capitalizeAndTranslateMsg(`${defaultTranslatePath}max`, 'cannot be longer than ${max} characters'),
    },
    types: {
      email: capitalizeAndTranslateMsg(`${defaultTranslatePath}email`, 'is not a valid email'),
      number: capitalizeAndTranslateMsg(`${defaultTranslatePath}number`, 'must be a number type'),
      url: capitalizeAndTranslateMsg(`${defaultTranslatePath}url`, 'is not a valid URL'),
    },
  };

  const rules = {
    user: {
      first_name: [{ max: 200 }, { required: true }],
      last_name: [{ max: 200 }, { required: true }],
      // 'email': [{ type: 'email' }, { required: true }],
      password: [{ min: 4 }, { max: 200 }],
      initial_key: [{ len: 130 }],
      phone_number: [{
        pattern: /^[0-9 ()+-]+$/,
        message: 'Phone number is not valid',
      }],
    },
    group: {
      group_name: [{ required: true }, { min: 1 }, { max: 200 }],
      weight: [{ type: 'number' }, { required: true }],
    },
    service: {
      service_name: [{ required: true }, { min: 1 }, { max: 200 }],
      service_domain: [{ type: 'url' }, { required: true }],
      initial_key: [{ len: 130 }, { required: true }],
    },
  };

  const isDefault = actorType === 'group' && [
    'ADMIN',
    'DEFAULT',
    'BAN',
  ].includes(group_name);

  const additionalTag = () => {
    const isRoot = rootPermsSignature;
    const tagName = isRoot ? 'root' : 'default';

    return (
      <>
        {(isRoot || isDefault) && (
          <Tag className={`tag-${isRoot ? 'purple' : 'gray'} ml-2`}>
            {capitalizeAndTranslateMsg(`auth.headers.${tagName}`, tagName).toUpperCase()}
          </Tag>
        )}
      </>
    );
  };

  const actorTypeActor = () => (
    <Tag className={`tag-${actorType} ml-2`}>
      {capitalizeAndTranslateMsg(`auth.headers.${actorType}`, actorType)}
    </Tag>
  );

  const onSubmitFunc = async () => {
    const changedInputs = actorForm.getFieldsValue(null, (meta) => meta.touched);
    const remove_actors_list = actorForm.getFieldValue('removeActorsList');
    const add_actors_list = actorForm.getFieldValue('addActorsList') || [];

    const {
      birthday,
      comment,
      description,
      email,
      first_name,
      group_name,
      initial_key,
      last_name,
      login,
      newKeyPair: generate_new_keys_for_user,
      password,
      phone_number,
      service_domain,
      service_name,
      typeOfUser: actor_type,
      weight,
    } = changedInputs;

    // console.log('changedInputs', changedInputs);

    try {
      await actorForm.validateFields();

      const data = {
        ...actorData,
        ...(actor_type ? { actor_type } : {}),
        ...(actor_type && actor_type === 'user' ? { generate_new_keys_for_user: true } : {}),
        ...(initial_key ? { initial_key } : {}),
        ...(generate_new_keys_for_user ? { generate_new_keys_for_user } : {}),
        ...(add_actors_list && add_actors_list.length !== 0 ? { add_actors_list } : {}),
        ...(remove_actors_list && remove_actors_list.length !== 0 ? { remove_actors_list } : {}),
        uinfo: {
          ...actorData.uinfo,
          ...(description ? { description } : {}),
          ...(first_name ? { first_name } : {}),
          ...(service_name ? { service_name } : {}),
          ...(service_domain ? { service_domain } : {}),
          ...(weight ? { weight } : {}),
          ...(group_name ? { group_name } : {}),
          ...(last_name ? { last_name } : {}),
          ...(email ? { email } : {}),
          ...(comment ? { comment } : {}),
          ...(password ? { password } : {}),
          ...(phone_number ? { phone_number } : {}),
          ...(login ? { login } : {}),
          ...(birthday ? { birthday: moment(birthday).format('YYYY-MM-DD') } : {}),
        },
      };

      // console.log('data data data', data);

      requestUpdateActor(data).then((resData) => {
        const {
          backup_filename,
        } = resData || {};

        antNotification.success(capitalizeAndTranslateMsg('auth.notifications.updated_successfully', 'updated successfully'));

        if (backup_filename) {
          downLoadBackupFileRequest(backup_filename, `${first_name}_${last_name}`);
        }

        onSuccessUpdate?.();
      });

      // setEditMode(false);
    } catch (e) {
      console.log('error', e);
      antNotification.error(
        capitalizeAndTranslateMsg(
          'auth.notifications.invalid_data',
          'invalid data entered',
        ),
      );
    } finally {
      onCancel();
    }
  };

  const onApplyFilter = () => {
    applyFilter([
      {
        key: 'uinfo.groups',
        value: actorURLUUID,
        label: 'Group with perms 2',
        className: '',
      },
    ]);
  };

  const getActorInitFormValues = () => {
    switch (actorType) {
      case 'classic_user':
      case 'user':
      case 'phantom':
        return userInitialValues;
      case 'group':
        return groupInitialValues;
      case 'service':
        return serviceInitialValues;
      default:
        return [];
    }
  };

  const editBtnOnClick = useCallback(() => {
    setEditMode(true);
  }, []);

  const onCancel = () => {
    setEditMode(false);
    actorForm.setFieldsValue(getActorInitFormValues());
    setFieldsIsChanged(false);
  };

  const getActorForm = () => {
    const infoProps = {
      actorForm,
      actorType,
      editBtnOnClick,
      setFieldsIsChanged,
      editMode,
      actorData,
      actorUUID,
      validateMessages,
      onSuccessUpdate,
    };

    switch (actorType) {
      case 'classic_user':
      case 'user':
      case 'phantom':
        return (
          <UserInfoFormItems
            {...infoProps}
            rules={rules.user}
            hideElements={['oldPassword', 'passwordConfirmation']}
          />
        );
      case 'group':
        return (
          <GroupInfoFormItems
            {...infoProps}
            rules={rules.group}
          />
        );
      case 'service':
        return (
          <ServiceInfoFormItems
            {...infoProps}
            rules={rules.service}
            onSuccessActorUpdate={onSuccessUpdate}
          />
        );
      default:
        return null;
    }
  };

  const collapseButton = () => (
    <div
      key="go_to_permissions_button"
      className="card-action"
      onClick={setCollapsed}
    >
      <Icon
        path={mdiInformationOutline}
        size={1.6}
        className="mr-2"
      />
      {capitalizeAndTranslateMsg('auth.headers.detailed_information', 'detailed information')}
    </div>
  );

  const initFunc = async () => {
    if (actorURLUUID && actorURLUUID !== actorUUID) {
      await requestGetActor({ uuid: actorURLUUID })
        .then(() => {})
        .catch(() => switchView());
    }
  };

  useEffect(() => {
    initFunc();
    onCancel();
  }, [actorURLUUID]);

  useEffect(() => {
    if (!isFetching) {
      actorForm.setFieldsValue(getActorInitFormValues());
    }
  }, [actorUUID, isFetching]);

  return (
    <Spin
      spinning={isFetching || isActorListFetching || !actorUUID}
    >
      <Form
        layout="vertical"
        autoComplete="off"
        form={actorForm}
        validateMessages={validateMessages}
        onFieldsChange={(param) => {
          setFieldsIsChanged(true);
        }}
      >
        <Row gutter={[24, 16]}>
          <Col span={24}>
            <Card
              className={`card ${showPerms ? '' : 'border-blue'}`}
              actions={showPerms ? [collapseButton()] : null}
            >
              <Row
                gutter={[16, 0]}
                wrap={false}
              >
                <Col flex="none">
                  <h4 className="header-primary">
                    {capitalizeAndTranslateMsg('auth.headers.actor_info', 'actor info')}
                  </h4>
                </Col>
                <Col
                  flex="auto"
                  className="d-flex justify-content-end"
                >
                  <Space
                    size={[5, 1]}
                    wrap
                    className="justify-content-end"
                  >
                    {actorType === 'group' && (
                    <Button
                      className="button-secondary-outlined"
                      size="small"
                      onClick={onApplyFilter}
                      disabled={isFetching || filterActive}
                    >
                      <Icon
                        path={mdiFilterOutline}
                        size={1}
                        className="mr-1"
                      />
                      {capitalizeAndTranslateMsg('auth.buttons.apply_filter', 'apply filter')}
                    </Button>
                    )}
                    {editMode && (
                    <>
                      <Button
                        className="button-secondary-outlined"
                        size="small"
                        onClick={onCancel}
                        disabled={isDefault || isFetching}
                      >
                        <Icon
                          path={mdiClose}
                          size={1}
                          className="mr-1"
                        />
                        {capitalizeAndTranslateMsg('auth.buttons.cancel', 'cancel')}
                      </Button>
                      <Form.Item
                        className="m-0"
                        shouldUpdate
                      >
                        <AntButtonWithMDI
                          className="button-primary"
                          size="small"
                          disabled={isDefault || isFetching || !fieldsIsChanged}
                          pathForMdi={mdiContentSaveOutline}
                          onClick={onSubmitFunc}
                          label={capitalizeAndTranslateMsg('54origins.verb.save', 'save')}
                        />
                      </Form.Item>
                    </>
                    )}
                    {!editMode && (
                    <AntButtonWithMDI
                      className="button-primary-outlined"
                      size="small"
                      disabled={isDefault || isFetching}
                      pathForMdi={mdiSquareEditOutline}
                      onClick={editBtnOnClick}
                      label={capitalizeAndTranslateMsg('54origins.verb.edit', 'edit')}
                    />
                    )}
                    <SyncDashboardBtnModal
                      disabledBtn={isFetching}
                    />
                    <DeleteButton
                      onSuccess={onSuccessDelete}
                      uuid={actorURLUUID}
                      disabled={isDefault || isFetching}
                      id={55}
                    >
                      <AntButtonWithMDI
                        className="button-danger-outlined"
                        size="small"
                        disabled={isDefault || isFetching}
                        pathForMdi={mdiTrashCanOutline}
                        label={capitalizeAndTranslateMsg('auth.buttons.remove_actor', 'remove actor')}
                      />
                    </DeleteButton>
                  </Space>
                </Col>
              </Row>
              <hr className="my-4" />
              <Row>
                <Col
                  span={24}
                  className="d-flex align-items-center"
                >
                  <Skeleton
                    loading={isFetching}
                    paragraph={false}
                    title={{
                      width: '50%',
                      style: {
                        margin: 0,
                        height: 24,
                      },
                    }}
                    active
                    round
                  >
                    <h3 className="header-primary m-0">
                      {getNameForActor(actorData)}
                    </h3>
                    {actorTypeActor()}
                    {additionalTag()}
                  </Skeleton>
                </Col>
                <Col
                  span={24}
                  className="d-flex align-items-center mt-2"
                >
                  <h5 className="header-primary">
                    UUID:
                    {' '}
                    {actorURLUUID}
                  </h5>
                  <Button
                    className="button-primary-link ml-2"
                    size="small"
                    onClick={() => textToClipboard(actorURLUUID)}
                  >
                    <Icon
                      path={mdiContentCopy}
                      size={1}
                    />
                  </Button>
                </Col>
                {!showPerms && !editMode && (
                <Col
                  span={24}
                  className="mt-3"
                >
                  <Button
                    className="button-primary-outlined w-100"
                    onClick={() => switchView('permissions', actorURLUUID)}
                  >
                    <Icon
                      path={mdiTune}
                      size={1.5}
                      className="mr-2"
                    />
                    {capitalizeAndTranslateMsg('auth.headers.permissions_settings', 'permissions settings')}
                  </Button>
                </Col>
                )}
              </Row>
              <hr className={collapsed ? 'my-4' : 'mt-4 mb-0'} />
              {showPerms ? (
                <Collapse
                  className="card-collapse"
                  activeKey={collapsed ? '1' : '0'}
                >
                  <Collapse.Panel
                    header={capitalizeAndTranslateMsg('auth.headers.detailed_information', 'detailed information')}
                    key="1"
                    forceRender
                  >
                    {getActorForm()}
                  </Collapse.Panel>
                </Collapse>
              ) : getActorForm()}

              <Row>
                <Col
                  span={24}
                  className="d-flex"
                >
                  <DateLabelFormat54origins
                    labelBeforeDate="created:"
                    date={created}
                    className="ml-auto"
                    textType="secondary"
                    format="DD-MM-YYYY | HH:mm"
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
}

export default ActorInfo;

ActorInfo.propTypes = {
  actorType: PropTypes.string.isRequired,
  actorURLUUID: PropTypes.string.isRequired,
  applyFilter: PropTypes.func,
  actorData: PropTypes.object,
  onSuccessDelete: PropTypes.func,
  onSuccessUpdate: PropTypes.func,
  showPerms: PropTypes.bool,
  switchView: PropTypes.func.isRequired,
};
