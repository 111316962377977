import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { mdiContentSaveEditOutline } from '@mdi/js';

import ProfilePage54origins from '../54origins/components/ProfilePage54origins';
import PageWrapper from './PageWrapper';
import ProfileEditFormComponent from './ProfileEditFormComponent';

import { ProfileConstants } from '../profile/constants';

import useCustomState from '../hooks/useCustomState';
import AntButtonWithMDI from './AntButtonWithMDI';
import { capitalizeAndTranslateMsg } from '../mainUtils';

const profileTypes = [
  ProfileConstants.GET_PROFILE_INFO_REQUEST,
  ProfileConstants.GET_PROFILE_INFO_SUCCESS,
  ProfileConstants.GET_PROFILE_INFO_FAILURE,
];

function AuthProfileWrapper() {
  const { t } = useTranslation();

  const {
    toggle,
    updateTime,
    setToggle,
    changeState,
  } = useCustomState();

  const updateActorCallback = useCallback(() => {
    changeState({ updateTime: new Date().getTime() });
  }, []);

  const editBtn = (
    <AntButtonWithMDI
      type="primary"
      className="ml-auto"
      onClick={setToggle}
      iconClassName="mb-1 mr-1"
      pathForMdi={mdiContentSaveEditOutline}
      label={capitalizeAndTranslateMsg('54origins.verb.edit', 'Edit')}
    />
  );

  const editComponent = useMemo(() => toggle && (
    <ProfileEditFormComponent
      setToggle={setToggle}
      updateActorCallback={updateActorCallback}
    />
  ), [toggle]);

  return (
    <PageWrapper>
      <ProfilePage54origins
        updateFromOuterComponent={updateTime}
        typesForRequest={profileTypes}
        editBtn={editBtn}
        editComponent={editComponent}
      />
    </PageWrapper>
  );
}

export default AuthProfileWrapper;
