// THIS IS GLOBAL REACT COMPONENT FROM ECOSYSTEM54.
// IF YOU WANT TO CHANGE THIS FILE, PLEASE CONTACT THE MAIN FRONTEND DEVELOPER ON THE ECOSYSTEM54
// UPDATED 2022.12.08 || YYYY/MM/DD
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { urlAPI } from '../../api/api';

function Login54Origins() {
  const initHTML = '<div style=\'display: flex;align-items: center;justify-content: center;height: 100%;\'>Loading...</div>';

  const [serverHtml, changeServerHtml] = useState(initHTML);
  const [documentIDs, changeDocumentIDs] = useState([]);

  const SERVICE_NAME = 'AUTH54';

  const appendScript = async (typeOfElement, scriptToAppend, id) => {
    const script = document.createElement('script');
    const scriptID = `customID${id}`;
    script.async = '';
    script.id = scriptID;

    changeDocumentIDs((prev) => [...prev, scriptID]);

    if (typeOfElement === 'scriptSrc') {
      script.src = scriptToAppend;
    } else {
      script.innerHTML = scriptToAppend;
    }
    document.body.appendChild(script);
  };

  const makePromiseStyle = (href) => new Promise((resolve, reject) => {
    const link = document.createElement('link');
    link.href = href;
    link.rel = 'stylesheet';
    link.onload = resolve;

    document.body.appendChild(link);
  });

  const appendScriptsToDocument = async (form) => {
    const listOfScripts = form.match(/<script[\s\S]*?>[\s\S]*?<\/script>/gi);

    for (let i = 0; i < listOfScripts.length; i++) {
      const singleScript = listOfScripts[i];

      if (singleScript.includes('src=')) {
        await appendScript('scriptSrc', singleScript.split('"')[1], i);
      } else {
        await appendScript('scriptBody', singleScript.substring(singleScript.indexOf('<script>') + 8, singleScript.indexOf('</script>')), i);
      }
    }
  };

  const appendStylesToDocument = async (form) => {
    const findAllStyles = form.match(/<link(.*)>/g);

    for (let i = 0; i < findAllStyles.length; i++) {
      await makePromiseStyle(findAllStyles[i].split('"')[1]);
    }
  };

  const getLoginForm = async (loginForm) => {
    const changedLoginForm = loginForm
      .substring(loginForm.indexOf('<!-- Start embed -->'), loginForm.indexOf('<!-- JS -->'))
      .replace('Logo', SERVICE_NAME);
    await appendStylesToDocument(loginForm);
    await appendScriptsToDocument(loginForm);
    await changeServerHtml(changedLoginForm);
  };

  const getLoginTemplate = () => (
    axios({
      method: 'get',
      url: `${urlAPI}/authorization/`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(({ data }) => {
        getLoginForm(data);
      })
  );

  useEffect(() => {
    localStorage.clear();

    getLoginTemplate();
  }, []);

  useEffect(() => {
    for (const id in documentIDs) {
      const el = document.getElementById(id);
      el?.remove();
    }
  }, []);

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: serverHtml,
      }}
    />
  );
}

export default Login54Origins;
