import PropTypes from 'prop-types';
import React from 'react';
import { mdiAccountDetailsOutline, mdiAccountGroupOutline, mdiNoteEditOutline } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import {
  Alert, Col, DatePicker, Form, Input, Row,
} from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';

import InfoBlock from '../../../components/InfoBlock';
import GenerateKeyPairForActorBtn from '../GenerateKeyPairForActorBtn';
import ChangeTypeOfUserBtn from '../ChangeTypeOfUserBtn';
import ListOfCurrentActorsAndChooseActors from '../ListOfCurrentActorsAndChooseActors';
import AddActorsForNewActor from '../AddActorsForNewActor';

import { capitalizeAndTranslateMsg } from '../../../mainUtils';

function UserInfoFormItems(props) {
  const {
    actorData,
    actorForm,
    actorType,
    actorUUID,
    creating,
    editMode,
    setFieldsIsChanged,
    hideElements = [],
    isProfile,
    onSuccessUpdate,
    rules,
  } = props;

  const { t } = useTranslation();

  const { setFieldsValue, getFieldsValue } = actorForm;
  const {
    typeOfUser = actorType,
    login,
    phone_number,
    email,
    newKeyPair = false,
  } = getFieldsValue(['typeOfUser', 'newKeyPair', 'phone_number', 'login', 'email']);

  const checkOneOfRequiredInput = () => {
    if (isEmpty(login) && isEmpty(phone_number) && isEmpty(email)) {
      return true;
    }
    return !(isEmpty(login) || isEmpty(phone_number) || isEmpty(email));
  };

  const customRules = {
    email: [{ type: 'email' }, {
      required: checkOneOfRequiredInput(),
      message: 'Please input your email ',
    }],
    login: [{ required: checkOneOfRequiredInput() }],
    phone_number: [
      {
        pattern: /^[0-9 ()+-]+$/,
        message: 'Phone number is not valid',
      },
      { required: checkOneOfRequiredInput() },
    ],
  };

  const switchValueCallback = (value) => {
    setFieldsValue({ newKeyPair: value });
  };

  const changeTypeOfUserCallback = (value) => {
    setFieldsValue({
      typeOfUser: value,
    });
  };

  const getSelectedActorsForNewActors = ({ addActorsList, removeActorsList }) => {
    setFieldsValue({
      addActorsList,
      removeActorsList,
    });
  };

  const updateActorCallback = ({ addActorsList, removeActorsList }) => {
    if (editMode || creating) {
      const changes = {
        addActorsList,
        ...(editMode ? { removeActorsList } : {}),
      };

      setFieldsValue(changes);

      if (editMode) {
        setFieldsIsChanged?.(true);
      }
    } else {
      onSuccessUpdate?.();
    }
  };

  const disabledDate = (current) => current > moment() || current < moment().subtract(80, 'year');

  const userIsClassic = actorType === 'classic_user' || typeOfUser === 'classic_user';

  return (
    <div>
      <InfoBlock
        iconPath={mdiAccountDetailsOutline}
        title={capitalizeAndTranslateMsg('auth.headers.user_detail', 'user detail')}
      >
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item
              label={capitalizeAndTranslateMsg('auth.headers.first_name', 'first name')}
              name="first_name"
              rules={rules.first_name}
            >
              <Input
                placeholder={capitalizeAndTranslateMsg('auth.placeholders.enter', { value: t('auth.headers.first_name') })}
                disabled={!editMode}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={capitalizeAndTranslateMsg('auth.headers.last_name', 'last name')}
              name="last_name"
              rules={rules.last_name}
            >
              <Input
                placeholder={capitalizeAndTranslateMsg('auth.placeholders.enter', { value: t('auth.headers.last_name_enter') })}
                disabled={!editMode}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          {userIsClassic && (
          <Col span={12}>
            <Form.Item
              label={capitalizeAndTranslateMsg('auth.headers.login', 'login')}
              name="login"
              rules={customRules.login}
            >
              <Input
                placeholder={capitalizeAndTranslateMsg('auth.placeholders.enter', { value: t('auth.headers.login') })}
                disabled={!editMode}
              />
            </Form.Item>
          </Col>
          )}
          <Col span={12}>
            <Form.Item
              label={capitalizeAndTranslateMsg('auth.headers.email', 'email')}
              name="email"
              rules={customRules.email}
            >
              <Input
                placeholder={capitalizeAndTranslateMsg('auth.placeholders.enter', { value: t('auth.headers.email') })}
                disabled={!editMode}
              />
            </Form.Item>
          </Col>
          {userIsClassic && !hideElements.includes('phone')
          && (
          <Col span={12}>
            <Form.Item
              label={capitalizeAndTranslateMsg('auth.headers.phone', 'phone number')}
              name="phone_number"
              rules={customRules.phone_number}
            >
              <Input
                placeholder={capitalizeAndTranslateMsg('auth.placeholders.enter', { value: t('auth.headers.phone') })}
                disabled={!editMode}
              />
            </Form.Item>
          </Col>
          )}
          <Col span={12}>
            <Form.Item
              label={capitalizeAndTranslateMsg('auth.headers.birthday', 'birthday')}
              name="birthday"
            >
              <DatePicker
                format="YYYY-MM-DD"
                className="w-100"
                placeholder={capitalizeAndTranslateMsg('auth.placeholders.select', { value: t('auth.headers.birthday') })}
                disabled={!editMode}
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          {userIsClassic && (
          <>
            {!hideElements.includes('oldPassword')
                && (
                <Col span={12}>
                  <Form.Item
                    label={capitalizeAndTranslateMsg('auth.headers.old_password', 'old password')}
                    name="old_password"
                    rules={rules.old_password}
                  >
                    <Input.Password
                      placeholder={capitalizeAndTranslateMsg('auth.placeholders.enter', { value: t('auth.headers.password') })}
                      disabled={!editMode}
                    />
                  </Form.Item>
                </Col>
                )}

            <Col span={12}>
              <Form.Item
                label={creating
                  ? capitalizeAndTranslateMsg('auth.headers.password', 'password')
                  : capitalizeAndTranslateMsg('auth.headers.new_password', 'new password')}
                name="password"
                rules={rules.password}
              >
                <Input.Password
                  placeholder={capitalizeAndTranslateMsg('auth.placeholders.enter', { value: t('auth.headers.password') })}
                  disabled={!editMode}
                />
              </Form.Item>
            </Col>
            {!hideElements.includes('passwordConfirmation')
                && (
                <Col span={12}>
                  <Form.Item
                    label={capitalizeAndTranslateMsg('auth.headers.old_password', 'password confirmation')}
                    name="password_confirmation"
                    rules={rules.password_confirmation}
                  >
                    <Input.Password
                      placeholder={capitalizeAndTranslateMsg('auth.placeholders.enter', { value: t('auth.headers.password') })}
                      disabled={!editMode}
                    />
                  </Form.Item>
                </Col>
                )}
          </>
          )}
        </Row>
        {!creating && editMode && (
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <Form.Item
              label={capitalizeAndTranslateMsg('auth.headers.user type', 'type of user')}
              name="typeOfUser"
            >
              <ChangeTypeOfUserBtn
                defaultUserType={actorType}
                changeTypeOfUserCallback={changeTypeOfUserCallback}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            {!userIsClassic
                  && (
                  <Form.Item
                    label={capitalizeAndTranslateMsg('auth.headers.user type', 'generate new key pair')}
                    name="newKeyPair"
                  >
                    <GenerateKeyPairForActorBtn
                      switchValueCallback={switchValueCallback}
                    />
                  </Form.Item>
                  )}
          </Col>
        </Row>
        )}
        {!userIsClassic && (editMode || creating) && (
        <Row>
          <Col>
            <Alert
              message="Backup info"
              description={`
                    After generating new keys or changing the user type, you will be prompted to download the user backup.                  
                    Default password: 1111                   
                  `}
              type="info"
              showIcon
            />
          </Col>
        </Row>
        )}
        <Row gutter={[16, 0]}>
          {!creating && !isProfile && !userIsClassic && (
          <Col span={24}>
            <Form.Item
              label={capitalizeAndTranslateMsg('auth.headers.initial_key', 'initial key')}
              name="initial_key"
              rules={rules.initial_key}
            >
              <Input.TextArea
                autoSize={{
                  minRows: 3,
                  maxRows: 5,
                }}
                placeholder={capitalizeAndTranslateMsg('auth.placeholders.enter', { value: t('auth.headers.initial_key') })}
                disabled={!editMode || newKeyPair}
              />
            </Form.Item>
          </Col>
          )}
        </Row>
      </InfoBlock>
      <InfoBlock
        iconPath={mdiAccountDetailsOutline}
        title={capitalizeAndTranslateMsg('auth.headers.user_groups', 'user groups')}
      >
        <Row>
          <Col span={24}>
            {!hideElements.includes('actorsModal') && actorUUID && (
              <ListOfCurrentActorsAndChooseActors
                actorData={actorData}
                btnModalLabel="Add or remove groups"
                iconModalPath={mdiAccountGroupOutline}
                onSaveCallBack={updateActorCallback}
                editMode={editMode}
              />
            )}
            {creating && (
              <AddActorsForNewActor
                actorType="user"
                onSaveCallBack={getSelectedActorsForNewActors}
                btnModalLabel="add groups"
              />
            )}
          </Col>
        </Row>
      </InfoBlock>

      {!isProfile && (
      <InfoBlock
        iconPath={mdiNoteEditOutline}
        title={capitalizeAndTranslateMsg('auth.headers.comment', 'comment')}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name="comment"
              rules={rules.comment}
            >
              <Input.TextArea
                placeholder={capitalizeAndTranslateMsg('auth.placeholders.enter', { value: t('auth.headers.comment') })}
                autoSize={{
                  minRows: 3,
                  maxRows: 5,
                }}
                disabled={!editMode}
              />
            </Form.Item>
          </Col>
        </Row>
      </InfoBlock>
      )}
    </div>
  );
}

export default UserInfoFormItems;

UserInfoFormItems.propTypes = {
  actorData: PropTypes.any,
  actorForm: PropTypes.object.isRequired,
  actorType: PropTypes.string.isRequired,
  actorUUID: PropTypes.string,
  creating: PropTypes.bool,
  editMode: PropTypes.bool,
  hideElements: PropTypes.array,
  isProfile: PropTypes.bool,
  onSuccessUpdate: PropTypes.func,
  rules: PropTypes.object,
};
