import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Col, Row, Space, Tooltip } from 'antd';
import update from 'immutability-helper';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import _ from 'lodash';
import Icon from '@mdi/react';
import { mdiArrowDown, mdiArrowRight, mdiArrowUp, mdiClose, mdiContentSaveOutline, mdiOpenInNew } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { antNotification, capitalize } from '../../mainUtils';
import { mdiArrowLeft } from '@mdi/js/commonjs/mdi';
import { ApiContext } from '../../api/ApiContextProvider';
import { getAllGroupsMap, isInfoFetching } from '../../actors/selectors';

import AntTableWithPagination from "../../components/AntTableWithPagination";
import PageWrapper from '../../components/PageWrapper';



const type = 'DraggableBodyRow';

const DraggableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}) => {
  const ref = useRef();
  const [
    {
      isOver,
      dropClassName
    }, drop
  ] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};

      if (dragIndex === index) {
        return {};
      }

      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ?
          ' drop-over-downward' :
          ' drop-over-upward'
      };
    },
    drop: item => {
      moveRow(item.index, index);
    }
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: monitor => (
      {
        isDragging: monitor.isDragging()
      }
    )
  });
  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};


const GroupsPriorityPage = () => {
  const { t } = useTranslation();
  const { requestGetAllGroups, requestUpdateActor } = useContext(ApiContext);

  const isFetching = useSelector(isInfoFetching);
  const allGroupsMap = useSelector(getAllGroupsMap);

  const [paginationOptions, changePaginationOptions] = useState({})
  const [groupsCount, changeGroupsCount] = useState(0)

  const [groupsWithoutDefault, setGroupsWithoutDefault] = useState([]);
  const [groupsSteps, setGroupsSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState(-1);

  const [orderRules, changeOrderRules] = useState({
    'order_by_column': 'weight',
    'order_by_rule': 'desc'
  });

  const {
    pageLimit,
    currentPage,
    offset
  } = paginationOptions;

  const handleSetAllGroups = (groups, movedIndex) => {
    const movedWeight = +_.get(groups, `[${movedIndex}].uinfo.weight`, 0);

    const newGroups = groups.reverse().reduce((acc, item, index, arr) => {
      const prevWeight = +_.get(acc, `[${index - 1}].uinfo.weight`, 0);
      const weight = +_.get(item, 'uinfo.weight', 0);
      const nextWeight = +_.get(arr, `[${index + 1}].uinfo.weight`, 0);

      if ((
        (
          weight > nextWeight || weight < prevWeight
        ) && weight === movedWeight
      ) || (
        weight === nextWeight || weight === prevWeight
      )) {
        return [
          ...acc, {
            ...item,
            uinfo: {
              ...item.uinfo,
              weight: prevWeight + 1
            }
          }
        ];
      }

      return [...acc, item];
    }, []).reverse();

    setGroupsSteps((prev) => [...prev, newGroups]);
    setCurrentStep((prev) => prev + 1);
    setGroupsWithoutDefault(newGroups);
  };

  const downPriority = (index) => {
    const newGroups = [...groupsWithoutDefault];
    [
      newGroups[index],
      newGroups[index + 1]
    ] = [
      newGroups[index + 1],
      newGroups[index]
    ];
    handleSetAllGroups(newGroups, index + 1);
  };

  const upPriority = (index) => {
    const newGroups = [...groupsWithoutDefault];
    [
      newGroups[index],
      newGroups[index - 1]
    ] = [
      newGroups[index - 1],
      newGroups[index]
    ];
    handleSetAllGroups(newGroups, index - 1);
  };

  const formatName = (cell, row) => (
    <div className={'d-flex align-items-center'}>
      <span className={'mr-2'}>
        {_.get(row, 'uinfo.group_name')}
      </span>
      <Link
        to={{ pathname: `/admin/actors?type=group&uuid=${cell}&view=info` }}
        target={'_blank'}
      >
        <Tooltip
          title={capitalize(t('auth.headers.go_to_actor', 'go to actor'))}
        >
          <Button
            className={'button-primary-link'}
            size={'small'}
          >
            <Icon path={mdiOpenInNew} size={1} />
          </Button>
        </Tooltip>
      </Link>
    </div>
  );

  const formatWeight = (cell, row) => (
    <>
      {_.get(row, 'uinfo.weight')}
    </>
  );

  const formatControls = (cell, row, index) => (
    <div className={'d-flex'}>
      <Button
        className={'button-warning-outlined px-2 mr-2'}
        size={'small'}
        disabled={index + 1 === groupsWithoutDefault.length}
        onClick={() => downPriority(index)}
      >
        <Icon path={mdiArrowDown} size={1} />
      </Button>
      <Button
        className={'button-primary-outlined px-2'}
        size={'small'}
        disabled={index === 0}
        onClick={() => upPriority(index)}
      >
        <Icon path={mdiArrowUp} size={1} />
      </Button>
    </div>
  );

  const changeSort = (param, sortOrder) => {
    changeOrderRules({
      'order_by_column': param,
      'order_by_rule': sortOrder === 'ascend' ? 'asc' : 'desc'
    })
  }

  const columns = [
    {
      dataIndex: 'uuid',
      key: 'name',
      className: 'p-3',
      title: capitalize(t('auth.headers.title', 'title')),
      render: formatName,
      sorter: (a, b, sortOrder) => changeSort('title', sortOrder),
    },
    {
      dataIndex: 'uuid',
      key: 'weight',
      className: 'p-3',
      title: capitalize(t('auth.headers.weight', 'weight')),
      render: formatWeight,
      sorter: (a, b, sortOrder) => changeSort('weight', sortOrder),
      defaultSortOrder: 'descend'
    },
    {
      dataIndex: 'uuid',
      key: 'controls',
      className: 'p-3',
      width: 85,
      title: '',
      render: formatControls
    }
  ];

  const components = {
    body: {
      row: DraggableBodyRow
    }
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = groupsWithoutDefault[dragIndex];
      handleSetAllGroups(
        update(groupsWithoutDefault, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow]
          ]
        }), hoverIndex
      );
    },
    [groupsWithoutDefault]
  );

  const setGroups = (actors) => {
    const compare = (a, b) =>
      +_.get(b, 'uinfo.weight', 0) -
      +_.get(a, 'uinfo.weight', 0);

    const groups = actors
      .filter((item) => {
        const weight = +_.get(item, 'uinfo.weight', 0);

        return weight < 4294967298 && weight > 0;
      })
      // .sort(compare);

    handleSetAllGroups(groups);
  };

  const updateGroup = (data) => {
    requestUpdateActor(data).then(() => {
      antNotification.success(capitalize(
        t(
          'auth.notifications.updated_successfully',
          'updated successfully'
        )));
    });
  };

  const onCancel = () => {
    setGroupsWithoutDefault(groupsSteps[0]);
    setCurrentStep(0);
    setGroupsSteps((prev) => [prev[0]]);
  };

  const onSubmit = async () => {
    for (const item of groupsWithoutDefault) {
      const weight = +_.get(item, 'uinfo.weight');
      const uuid = _.get(item, 'uuid');
      const oldGroupData = allGroupsMap.get(uuid);
      const oldWeight = +_.get(oldGroupData, 'uinfo.weight');

      if (weight !== oldWeight) {
        await updateGroup({
          ...item,
          uinfo: {
            ...item.uinfo,
            weight
          }
        });
      }
    }

    setCurrentStep(0);
    setGroupsSteps([groupsWithoutDefault]);
  };

  const backStep = () => {
    setGroupsWithoutDefault(groupsSteps[currentStep - 1]);
    setCurrentStep((prev) => prev - 1);
  };

  const forwardStep = () => {
    setGroupsWithoutDefault(groupsSteps[currentStep + 1]);
    setCurrentStep((prev) => prev + 1);
  };

  const initFunc = () => {
    const data = {
      limit: pageLimit,
      offset,
      ...orderRules
    }
    requestGetAllGroups(data)
      .then(({ actors, total }) => {

        changeGroupsCount(total)
        setGroups(actors)
      });
  };

  useEffect(() => {
    if(currentPage && pageLimit) {
      initFunc();
    }
  }, [pageLimit, currentPage, JSON.stringify(orderRules)]);


  return (
    <PageWrapper
      title={capitalize(t('auth.pages.groups_priority', 'groups priority'))}
    >
      <Row gutter={[30, 0]}>
        <Col span={14}>
          <Card className={'card'}>
            <Row className={'mb-3'} gutter={[10, 10]}>
              <Col
                className={'d-flex align-items-center'}
              >
                <h4 className={'header-primary mb-0 mr-3'}>
                  {capitalize(t('auth.headers.set_groups_priority', 'set groups priority'))}
                </h4>
              </Col>
              <Col flex={'auto'} className={'d-flex justify-content-end'}>
                <Space size={[5, 1]} wrap className={'justify-content-end'}>
                  <Button
                    className={'button-primary-outlined'}
                    size={'small'}
                    disabled={currentStep < 1}
                    onClick={backStep}
                  >
                    <Icon path={mdiArrowLeft} size={1} className={'mr-1'} />
                    {capitalize(t('auth.buttons.back', 'back'))}
                  </Button>
                  <Button
                    className={'button-primary-outlined'}
                    size={'small'}
                    disabled={currentStep + 1 === groupsSteps.length}
                    onClick={forwardStep}
                  >
                    <Icon path={mdiArrowRight} size={1} className={'mr-1'} />
                    {capitalize(t('auth.buttons.forward', 'forward'))}
                  </Button>
                  <Button
                    className={'button-secondary-outlined'}
                    size={'small'}
                    onClick={onCancel}
                    disabled={groupsSteps.length < 2}
                  >
                    <Icon path={mdiClose} size={1} className={'mr-1'} />
                    {capitalize(t('auth.buttons.cancel', 'cancel'))}
                  </Button>
                  <Button
                    className={'button-primary'}
                    size={'small'}
                    onClick={onSubmit}
                    disabled={groupsSteps.length < 2}
                  >
                    <Icon path={mdiContentSaveOutline} size={1} className={'mr-1'} />
                    {capitalize(
                      t('auth.buttons.save', 'save'))}
                  </Button>
                </Space>
              </Col>
            </Row>
            <hr className={'my-4'} />
            <DndProvider backend={HTML5Backend}>
              <div className={'table-drag-sorting'}>
                <AntTableWithPagination
                  getPaginationOptions={changePaginationOptions}
                  data={groupsWithoutDefault}
                  columns={columns}
                  components={components}
                  loading={isFetching}
                  // defaultPageLimit={5}
                  // pageSizeOptions={[5, 10, 15]}
                  onRow={(row, index) => (
                    { index, moveRow }
                  )}
                  total={groupsCount}
                />
              </div>
            </DndProvider>
          </Card>
        </Col>
      </Row>
    </PageWrapper>
  );
};

export default GroupsPriorityPage;

DraggableBodyRow.propTypes = {
  className: PropTypes.string,
  index: PropTypes.number,
  moveRow: PropTypes.func,
  style: PropTypes.object
};
