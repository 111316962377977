import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { Button, Card, Col, Descriptions, Empty, Row, Spin, Tag, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { getAllGroups, isActorsFetching } from '../../actors/selectors';
import { capitalize } from '../../mainUtils';
import { useTranslation } from 'react-i18next';
import PolicyExportJSON from './PolicyExportJSON';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiOpenInNew } from '@mdi/js';
import { ApiContext } from '../../api/ApiContextProvider';


const PolicyGroupsDescription = ({
  documentName,
  documentData
}) => {
  const { t } = useTranslation();

  const { requestGetAllServices, requestGetAllGroups } = useContext(ApiContext);

  const fetching = useSelector(isActorsFetching);
  const allGroups = useSelector(getAllGroups);

  const weightCompare = (a, b) =>
    +_.get(b, 'uinfo.weight', 0) -
    +_.get(a, 'uinfo.weight', 0);

  const descriptionPlaceholder = (
    <span className={'text-secondary'}>
      {capitalize(t(
        'auth.placeholders.no_description',
        'no description'
      ))}
    </span>
  );

  const groupRender = (group) => {
    const getFromUinfo = (key) => _.get(
      group,
      `uinfo.${key}`,
      ''
    );

    const weight = +getFromUinfo('weight', 0);

    const getWeightColor = () => {
      switch (weight) {
        case 4294967299:
          return 'red';
        case 4294967298:
          return 'volcano';
        case 0:
          return 'blue';
        default:
          return 'default';
      }
    };

    const label = (
      <Row wrap={false}>
        <Col flex={'auto'}>
          <span className={`text-${getWeightColor()} mr-2`}>
            {getFromUinfo('group_name')}
          </span>
          <Link
            to={{ pathname: `/admin/actors?type=group&uuid=${group.uuid}&view=info` }}
            target={'_blank'}
          >
            <Tooltip
              title={capitalize(t('auth.headers.go_to_actor', 'go to actor'))}
            >
              <Button
                className={'button-primary-link'}
                size={'small'}
              >
                <Icon path={mdiOpenInNew} size={1} />
              </Button>
            </Tooltip>
          </Link>
        </Col>
        <Col>
          <Tooltip
            title={`${capitalize(t('auth.headers.weight', 'weight'))}: ${weight}`}
          >
            <Tag
              className={`tag-${getWeightColor()}-light`}
            >
              {weight}
            </Tag>
          </Tooltip>
        </Col>
      </Row>
    );

    return (
      <Descriptions.Item
        key={`group-${group.uuid}`}
        label={label}
        labelStyle={{ width: '33%', padding: '12px 16px' }}
      >
        <span className={'text-primary'}>
          {getFromUinfo('description') || descriptionPlaceholder}
        </span>
      </Descriptions.Item>
    );
  };

  useEffect(() => {
    requestGetAllServices()

    if (allGroups.length === 0) {
      requestGetAllGroups();
    }
  }, []);


  return (
    <Spin spinning={fetching}>
      <Card
        className={'card'}
      >
        <Row>
          <Col flex={'auto'}>
            <h4 className={'header-primary'}>
              {capitalize(t('auth.headers.groups_description', 'groups description'))}
            </h4>
          </Col>
          <Col>
            <PolicyExportJSON
              documentName={documentName}
              documentData={documentData}
            />
          </Col>
        </Row>
        <hr className={'my-4'} />
        <Row>
          <Col span={24}>
            {allGroups.length > 0 ? (
              <Descriptions
                bordered
                column={1}
              >
                {allGroups.sort(weightCompare).map(groupRender)}
              </Descriptions>
            ) : (
              <Empty />
            )}
          </Col>
        </Row>
      </Card>
    </Spin>
  );
};

export default PolicyGroupsDescription;

PolicyGroupsDescription.propTypes = {
  documentData: PropTypes.string,
  documentName: PropTypes.string
};
