import React from 'react';
import {
  mdiAlertBoxOutline,
  mdiCheckboxMarkedCircleOutline,
  mdiRefresh,
  mdiSend,
  mdiTrashCanOutline,
} from '@mdi/js';
import { capitalize } from 'lodash';
import { Tag } from 'antd';
import Icon from '@mdi/react';
import { useTranslation } from 'react-i18next';

function TagForLogComponent({
  typeOfTag,
  className,
}) {
  const { t } = useTranslation();

  const typeOfTagLower = typeOfTag?.toLowerCase();

  const icon = () => {
    switch (typeOfTagLower) {
      case 'create':
        return mdiCheckboxMarkedCircleOutline;
      case 'update':
      case 'update actor':
        return mdiRefresh;
      case 'delete':
        return mdiTrashCanOutline;
      case 'actor permactions':
        return mdiAlertBoxOutline;
      case 'send to service':
        return mdiSend;
      default:
        return '';
    }
  };

  const title = () => {
    switch (typeOfTagLower) {
      case 'create':
        return capitalize(t('auth.headers.created', 'created'));
      case 'update':
        return capitalize(t('auth.headers.updated', 'updated'));
      case 'delete':
        return capitalize(t('auth.headers.deleted', 'deleted'));
      default:
        return typeOfTag;
    }
  };

  const getTagColor = () => {
    switch (typeOfTagLower) {
      case 'create':
        return 'green';
      case 'update':
      case 'update actor':
      case 'send to service':
        return 'blue';
      case 'delete':
        return 'red';
      case 'actor permactions':
        return 'volcano';
      default:
        return '#9B9B9B';
    }
  };

  return (
    <Tag
      className={className}
      icon={(
        <Icon
          path={icon()}
          size={1}
          className="mr-1 mb-1"
        />
      )}
      color={getTagColor()}
    >
      {title()}
    </Tag>
  );
}

export default TagForLogComponent;
