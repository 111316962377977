import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import ListOfActorsTags from './ListOfActorsTags';
import ActorsModalBtn from './ActorsModalBtn';

import { getDefaultGroups } from '../selectors';

function AddActorsForNewActor({
  actorType,
  btnModalLabel,
  hideElements,
  getRemovedUserCallback,
  onSaveCallBack,
}) {
  const defaultGroups = useSelector(getDefaultGroups);

  const defaultGroup = defaultGroups.filter((item) => item.uinfo.group_name === 'DEFAULT');
  const initLocalActors = actorType === 'user' || actorType === 'classic_user' ? defaultGroup : [];

  const [localActors, setLocalActors] = useState(initLocalActors);

  const onSaveCallBackAndSetLocalActors = (actors) => {
    const { selectedActors } = actors;
    setLocalActors(selectedActors);

    onSaveCallBack?.(actors);
  };

  const actorsUUIDs = localActors.map((item) => item.uuid);

  return (
    <>
      <ListOfActorsTags
        actors={localActors}
      />
      <ActorsModalBtn
        currentActors={actorsUUIDs}
        actorType={actorType}
        btnModalLabel={btnModalLabel}
        hideElements={hideElements}
        doNotMakeRequest={['saveActors']}
        onSaveCallBack={onSaveCallBackAndSetLocalActors}
        getRemovedUserCallback={getRemovedUserCallback}
      />
    </>
  );
}

export default AddActorsForNewActor;

AddActorsForNewActor.propTypes = {
  actorType: PropTypes.string,
  btnModalLabel: PropTypes.string,
  getRemovedUserCallback: PropTypes.func,
  hideElements: PropTypes.array,
  onSaveCallBack: PropTypes.func,
};
