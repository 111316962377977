import React, { useEffect, useState } from 'react';
import {
  Col, Dropdown, Menu, Row, Tag,
} from 'antd';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import {
  mdiAccountCircleOutline, mdiGhostOutline, mdiLogoutVariant, mdiMenuDown,
} from '@mdi/js';
import Icon from '@mdi/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import UserAvatar from './UserAvatar';
import MasqueradingModal from './MasqueradingModal';

import {
  getMeIsAdmin, getMeIsRoot, getMyPermissions, getProfileInfo,
} from '../profile/selectors';
import { logout } from '../actors/actions/actorsActions';
import { capitalize } from '../mainUtils';
import { getAllUsersMap } from '../actors/selectors';

function ProfileMenu() {
  const { t } = useTranslation();

  const profileInfo = useSelector(getProfileInfo);
  const myPermissions = useSelector(getMyPermissions);
  const meIsRoot = useSelector(getMeIsRoot);
  const meIsAdmin = useSelector(getMeIsAdmin);
  const allUsersMap = useSelector(getAllUsersMap);

  const [modalVisible, setModalVisible] = useState(false);
  const [masqueradedUsers, setMasqueradedUsers] = useState([]);
  const [masqueradingAvailable, setMasqueradingAvailable] = useState(false);

  const {
    actor: {
      is_admin,
      root,
      uinfo: {
        first_name,
        last_name,
      } = {},
    } = {},
  } = profileInfo || {};

  const toggleModal = () => setModalVisible((prev) => !prev);

  const getUserName = (uuid) => {
    if (allUsersMap.has(uuid)) {
      const userData = allUsersMap.get(uuid);

      return `${_.get(userData, 'uinfo.first_name')} ${_.get(userData, 'uinfo.last_name')}`;
    }
    return '';
  };

  const initFunc = () => {
    if (meIsRoot || meIsAdmin) {
      return;
    }

    const { actor, group } = myPermissions;
    const permissions = [...actor, ...group.sort((a, b) => b.weight - a.weight)];
    const permissionIndex = _.findIndex(permissions, ['permaction_uuid', '43204251-47fe-46c5-8277-e2ddac0451c4']);

    if (permissionIndex !== -1) {
      const uuids = _.get(permissions, `[${permissionIndex}].params.masquerade`, []);
      const personalInfo = _.get(permissions, `[${permissionIndex}].params.personal_info`, []);
      const personalInfoMap = new Map([
        ...personalInfo.map((item) => [
          _.get(item, 'uuid', ''),
          `${_.get(item, 'first_name', '')} ${_.get(item, 'last_name', '')}`,
        ]),
      ]);

      setMasqueradedUsers(uuids.map((uuid) => (
        {
          value: uuid,
          label: personalInfoMap.get(uuid) || getUserName(uuid) || uuid,
        }
      )));

      setMasqueradingAvailable(true);
    } else {
      setMasqueradingAvailable(false);
    }
  };

  useEffect(() => {
    initFunc();
  }, [JSON.stringify(myPermissions)]);

  const profileMenu = (
    <Menu>
      <Menu.Item
        key="profile"
        className="header-dropdown-item pl-3"
      >
        <Link to="/admin/profile">
          <Icon path={mdiAccountCircleOutline} size={1.5} className="mr-3" />
          {capitalize(t('auth.headers.profile', 'profile'))}
          {root && (
            <Tag className="tag-purple ml-2">
              {t('auth.headers.root', 'root').toUpperCase()}
            </Tag>
          )}
          {is_admin && (
          <Tag className="tag-red ml-2">
            {t('auth.headers.admin', 'admin').toUpperCase()}
          </Tag>
          )}
        </Link>
      </Menu.Item>
      {masqueradingAvailable && (
        <Menu.Item
          key="hw1"
          className="header-dropdown-item"
          onClick={toggleModal}
        >
          <Icon path={mdiGhostOutline} size={1.5} className="mr-3" />
          {capitalize(t('auth.headers.masquerading', 'masquerading'))}
        </Menu.Item>
      )}
      <Menu.Item
        key="logout"
        className="header-dropdown-item item-red pl-3"
      >
        <Link to="/admin" onClick={logout}>
          <Icon path={mdiLogoutVariant} size={1.5} className="mr-3" />
          {capitalize(t('auth.headers.log_out', 'log out'))}
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="header-dropdown-wrapper h-100">
      <MasqueradingModal
        visible={modalVisible}
        onCancel={toggleModal}
        users={masqueradedUsers}
      />
      <Dropdown
        trigger={['click']}
        overlay={profileMenu}
        placement="bottom"
        className="h-100"
      >
        <Row className="cursor-pointer">
          <Col className="d-flex align-items-center">
            {profileInfo.actor && (
              <UserAvatar
                userUUID={profileInfo.actor.uuid}
                userFirstName={first_name}
                userLastName={last_name}
                size={32}
              />
            )}
          </Col>
          <Col className="d-flex align-items-center ml-3">
            <h4 className="m-0" style={{ userSelect: 'none' }}>
              {first_name}
              {' '}
              {last_name}
            </h4>
          </Col>
          <Col className="d-flex align-items-center">
            <Icon path={mdiMenuDown} size={1.5} />
          </Col>
        </Row>
      </Dropdown>
    </div>
  );
}

export default ProfileMenu;
