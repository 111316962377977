import { mdiPlusCircle } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Button,
  Checkbox,
  Col,
  Input,
  Row,
} from 'antd';
import _, { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TagsSettingList from '../../components/TagsSettingList';

import { capitalize } from '../../mainUtils';

function ActorsListHeader({
  actorUUID,
  ignoreSearchCase,
  isCreateView,
  newSelectedTagsOfGroups,
  onChangeSearch,
  onSearchClick,
  onSelectTags,
  selectedFilters,
  setIgnoreSearchCase,
  switchView,
}) {
  const { t } = useTranslation();

  console.log('RENDER HEADER')

  const showMoreBtn = {
    uuid: 'addMore',
    actor_type: 'group',
    uinfo: { group_name: 'add more...' },
    unselectable: true,
  };

  const [tagsOfGroups, changeTagsOfGroups] = useState([showMoreBtn]);

  const filterTags = [
    {
      tags: [
        {
          key: 'all',
          value: 'all',
          label: capitalize(t('auth.headers.all_actors', 'all actors')),
        },
      ],
      isReset: true,
    },
    {
      tags: [
        {
          key: 'actor_type',
          value: 'user',
          label: capitalize(t('auth.headers.users', 'users')),
        },
        {
          key: 'actor_type',
          value: 'group',
          label: capitalize(t('auth.headers.groups', 'groups')),
        },
        {
          key: 'actor_type',
          value: 'service',
          label: capitalize(t('auth.headers.services', 'services')),
        },
        {
          key: 'is_root',
          value: 'true',
          label: 'Is ROOT',
          className: 'ml-4 ROOT',
        },
      ],
    },
    {
      tags: [
        ...tagsOfGroups.map((item) => {
          const label = _.get(item, 'uinfo.group_name');
          let className = '';

          if (['ADMIN', 'DEFAULT', 'BAN'].includes(label)) {
            className = label;
          }

          if (item.value && item.label) {
            return item;
          }
          return {
            key: 'uinfo.groups',
            value: item.uuid,
            label,
            className,
            unselectable: get(item, 'unselectable', false),
          };
        }),
      ],
      isRow: true,
      rowText: capitalize(t('auth.headers.groups', 'groups')),
      className: 'isGroups',
    },
  ];

  useEffect(() => {
    const newMapArr = new Map([...tagsOfGroups, ...newSelectedTagsOfGroups].map((item) => [item.uuid || item.value, item]));

    changeTagsOfGroups([...newMapArr].map(([name, value]) => value));
  }, [newSelectedTagsOfGroups]);

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col className="d-flex align-items-center">
          <h4 className="header-primary mb-0 mr-3">
            {capitalize(t('auth.pages.actors', 'actors'))}
          </h4>
        </Col>
        <Col
          className="d-flex align-items-center"
          span={7}
        >
          <Input.Search
            allowClear
            onSearch={onSearchClick}
            className="w-100"
            onChange={onChangeSearch}
            placeholder={capitalize(t(
              'auth.placeholders.search_actor',
              'enter actor name or UUID',
            ))}
          />
        </Col>
        <Col
          className="d-flex align-items-center ml-3"
        >
          <Checkbox
            checked={ignoreSearchCase}
            onChange={(e) => {
              setIgnoreSearchCase(e.target.checked);
            }}
          >
            <h5 className="header-secondary mb-0">
              {capitalize(t('auth.headers.ignore_case', 'ignore case'))}
            </h5>
          </Checkbox>
        </Col>
        <Col
          className="d-flex ml-auto flex-wrap justify-content-end"
        >
          {actorUUID && (
            <Button
              className="flex-fill button-warning-outlined mr-2"
              onClick={() => switchView()}
            >
              {capitalize(t('auth.buttons.reset', 'reset'))}
            </Button>
          )}
          <Button
            className={isCreateView ? 'button-warning-outlined' : 'button-primary-outlined'}
            onClick={() => {
              if (isCreateView) {
                switchView();
              } else {
                switchView('create');
              }
            }}
          >
            <Icon
              path={mdiPlusCircle}
              size={1.5}
              className="slow mr-2"
              rotate={isCreateView ? 765 : 0}
            />
            {isCreateView ? (
              capitalize(t('auth.buttons.cancel', 'cancel'))
            ) : (
              capitalize(t('auth.buttons.create_actor', 'create actor'))
            )}
          </Button>
        </Col>
      </Row>
      <hr className="my-4" />
      <Row>
        <Col>
          <TagsSettingList
            defaultSelectedTags={selectedFilters}
            allTags={filterTags}
            onSelectTags={onSelectTags}
            id="allActors"
            small
          />
        </Col>
      </Row>
    </>
  );
}

export default ActorsListHeader;

ActorsListHeader.propTypes = {
  actorUUID: PropTypes.string,
  ignoreSearchCase: PropTypes.bool,
  isCreateView: PropTypes.bool,
  onChangeSearch: PropTypes.func.isRequired,
  onSelectTags: PropTypes.func.isRequired,
  selectedFilters: PropTypes.array,
  setIgnoreSearchCase: PropTypes.func.isRequired,
  switchView: PropTypes.func.isRequired,
};
