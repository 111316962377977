import React, {
  useCallback,
  useContext, useEffect, useReducer, useState,
} from 'react';
import { useSelector } from 'react-redux';
import Icon from '@mdi/react';
import {
  mdiCheckCircleOutline, mdiCloseCircleOutline, mdiPlusCircleOutline, mdiReload, mdiTrashCanOutline,
} from '@mdi/js';
import {
  Col, Row, Spin,
} from 'antd';

import ColumnsWrapperForBasicActorList from './ColumnsWrapperForBasicActorList';
import BtnSendRequestAndShowResult from '../../components/BtnSendRequestAndShowResult';

import { ApiContext } from '../../api/ApiContextProvider';
import { getActorsInfo } from '../selectors';
import { getAuthServiceInfo } from '../../auth/selectors';
import useTimeout from '../../hooks/useTimeout';
import AntButtonWithMDI from '../../components/AntButtonWithMDI';
import { capitalizeAndTranslateMsg, translateMsg } from '../../mainUtils';

function CreateActorOnService() {
  const {
    requestCreateActorOnService,
    requestUpdateActorOnService,
    requestDeleteActorOnService,
    requestGetActorExistenceOnServicesData,
  } = useContext(ApiContext);

  const { reset } = useTimeout(() => getServicesWhereActorCreatedOrNotCreated(), 1500);

  const [fetching, setFetching] = useReducer((previous) => !previous, false);

  const actorData = useSelector(getActorsInfo);
  const authInfo = useSelector(getAuthServiceInfo);
  const authUUID = authInfo.uuid;

  const [currentTime, setCurrentTime] = useState('');
  const [servicesWhereActorExist, setServicesWhereActorExist] = useState([]);

  const {
    uuid: actorUUID,
    actor_type: actorType,
  } = actorData || {};

  const actorIsGroupOrService = actorType === 'group' || actorType === 'service';

  const actorIsCreated = (uuid) => (
    servicesWhereActorExist.includes(uuid) || actorIsGroupOrService || uuid === authUUID
  );

  const createOrUpdateActorOnService = (serviceUUID) => (actorIsCreated(serviceUUID)
    ? requestUpdateActorOnService(actorUUID, serviceUUID)
    : requestCreateActorOnService(actorUUID, serviceUUID));

  const getServicesWhereActorCreatedOrNotCreated = useCallback(async () => {
    await setFetching();
    let servicesWhereActorExistData = await requestGetActorExistenceOnServicesData(actorUUID);

    servicesWhereActorExistData = servicesWhereActorExistData?.existence || [];

    setServicesWhereActorExist(servicesWhereActorExistData);
    setCurrentTime(new Date().getTime());
    await setFetching();
  }, []);

  const actionCreateActorOnService = [
    {
      dataIndex: 'uuid',
      key: 'actorExist',
      className: 'p-2',
      title: capitalizeAndTranslateMsg('54origins.noun.exists', 'exists'),
      render: (cell) => (
        <Icon
          size={2}
          path={actorIsCreated(cell) ? mdiCheckCircleOutline : mdiCloseCircleOutline}
          color={actorIsCreated(cell) ? 'green' : 'red'}
        />
      ),
    },
    {
      dataIndex: 'uuid',
      key: 'actions',
      className: 'p-2',
      title: capitalizeAndTranslateMsg('54origins.noun.action', 'action'),
      width: 100,
      render: (cell) => (
        <Row>
          <Col span={12}>
            <BtnSendRequestAndShowResult
              action={() => createOrUpdateActorOnService(cell)}
              btnLabel={`
                ${capitalizeAndTranslateMsg(`54origins.verb.${actorIsCreated(cell) ? 'update' : 'create'}`)} 
                ${translateMsg('54origins.noun.actor')} 
                ${translateMsg('54origins.noun.service.2')}`}
              disabledBtn={cell === authUUID}
              finallyActionCallback={reset}
              iconBeforeLabel
              iconSize={1.2}
              pathForIcon={actorIsCreated(cell) ? mdiReload : mdiPlusCircleOutline}
              tooltipPlacement="left"
            />
          </Col>
          {actorIsCreated(cell) && !actorIsGroupOrService && (
            <Col span={12}>
              <BtnSendRequestAndShowResult
                action={() => requestDeleteActorOnService(actorUUID, cell)}
                btnLabel={capitalizeAndTranslateMsg('54origins.verb.delete')}
                btnType="danger"
                disabledBtn={cell === authUUID}
                finallyActionCallback={reset}
                iconBeforeLabel
                iconSize={1.2}
                pathForIcon={mdiTrashCanOutline}
                popConfirmTitle={(
                  <div>
                    <div>Attention! After successful deletion of the actor,</div>
                    <div>all his personal perms for this service will be deleted,</div>
                    <div>are you sure?</div>
                  </div>
                )}
                tooltipPlacement="right"
                withPopConfirm
              />
            </Col>
          )}
        </Row>
      ),
    }];

  useEffect(() => {
    if (actorUUID) {
      getServicesWhereActorCreatedOrNotCreated();
    }
  }, [actorUUID]);

  return (
    <>
      <Row>
        <Col span={24}>
          <AntButtonWithMDI
            onClick={getServicesWhereActorCreatedOrNotCreated}
            label={capitalizeAndTranslateMsg('54origins.verb.update', 'update')}
            disabled={fetching}
            pathForMdi={mdiReload}
            sizeMdi={1.2}
            iconClassName="mb-1 mr-1"
            type="primary"
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Spin spinning={fetching}>
            <ColumnsWrapperForBasicActorList
              getActorTypes={['service']}
              typeOfColumns="servicesSessions"
              additionalColumns={actionCreateActorOnService}
              getListOfActorsAgain={currentTime}
            />
          </Spin>
        </Col>
      </Row>
    </>
  );
}

export default CreateActorOnService;

// CreateActorOnServiceBtn.propTypes = {
//   service: PropTypes.string,
// };
