import PropTypes from 'prop-types';
import React, {
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { Button, Modal } from 'antd';
import Icon from '@mdi/react';
import { mdiPlusCircleOutline } from '@mdi/js';

import ModalListOfActors from './ModalListOfActors';
import ListOfActorsTags from './ListOfActorsTags';

import {
  antNotification, capitalizeAndTranslateMsg, stopPropagation,
} from '../../mainUtils';
import { ApiContext } from '../../api/ApiContextProvider';

function ActorsModalBtn({
  actorType,
  actorUUID,
  btnModalLabel,
  currentActors = [],
  doNotMakeRequest = [],
  getRemovedUserCallback,
  hideElements = [],
  iconPath = mdiPlusCircleOutline,
  listOfActorsActionBtnLabel,
  modalTitle,
  onCancelModalCallback,
  onOkModalCallback,
  openModalFromOuter,
  onSaveCallBack,
}) {
  const { requestUpdateActor, getActors, requestGetActor } = useContext(ApiContext);

  const defaultState = {
    selectedActors: [],
    addActorsList: [],
    removeActorsList: [],
  };

  const [modalVisible, changeModalVisible] = useReducer((state) => !state, false);
  const [state, setState] = useState(defaultState);

  const {
    selectedActors,
    addActorsList,
    removeActorsList,
  } = state || {};

  const localActorsIsChanged = addActorsList.length !== 0 || removeActorsList.length !== 0;

  const changeState = (params) => {
    // console.log('params', params);
    setState((prev) => ({ ...prev, ...params }));
  };

  const getSelectedActors = (actors) => {
    // console.log('getSelectedActors', actors);
    const actorsUUIDs = actors.map((item) => item.uuid);

    changeState({
      selectedActors: [...selectedActors, ...actors],
      addActorsList: [...addActorsList, ...actorsUUIDs],
    });
  };

  const getDataForListOfActorsRequest = () => {
    switch (actorType) {
      case 'user':
      case 'classic_user':
      case 'service':
      case 'newService':
        return {
          actor_type: ['group'],
          uuid__not: selectedActors.map(({ uuid }) => uuid),
        };
      case 'group':
      case 'newGroup':
        return {
          actor_type: ['user', 'classic_user'],
          uuid__not: selectedActors.map(({ uuid }) => uuid),
        };
      default:
        break;
    }
  };

  const onOkModal = () => {
    changeModalVisible();

    onOkModalCallback?.();
  };

  const removeSelectedActors = (removeUUID) => {
    const removedActorsList = [...removeActorsList, removeUUID];

    changeState({
      selectedActors: selectedActors.filter(({ uuid }) => uuid !== removeUUID),
      removeActorsList: removedActorsList,
    });

    // getRemovedUserCallback?.(removedActorsList);
  };

  const getSelectedActorsRequest = () => {
    const data = {};

    switch (actorType) {
      case 'user':
      case 'classic_user':
      case 'service':
        data.actor_type = ['group'];

        if (currentActors.length !== 0) {
          data.uuid = currentActors;

          return getActors(data, []);
        }
        const actorConstants = [
          'GET_ACTOR_FOR_ACTORS_MODAL_FETCHING',
          'GET_ACTOR_FOR_ACTORS_MODAL_SUCCESS',
          'GET_ACTOR_FOR_ACTORS_MODAL_FAILURE',
        ];

        return requestGetActor({
          uuid: actorUUID,
          constants: actorConstants,
        }).then((res) => {
          const { groups } = res || {};

          if (groups.length !== 0) {
            data.uuid = groups.map(({ uuid }) => uuid);

            return getActors(data, []);
          }
        });
      case 'newService':
        data.actor_type = ['group'];

        if (currentActors.length !== 0) {
          data.uuid = currentActors;
          return getActors(data, []);
        }

        break;
      case 'group':
        data.uinfo = {};
        data.uinfo.groups = [actorUUID];
        return getActors(data, []);

      case 'newGroup':
        if (currentActors.length !== 0) {
          data.actor_type = ['user', 'classic_user'];
          data.uuid = currentActors;
          return getActors(data, []);
        }
        break;
      default:
        return [];
    }
  };

  const onCancel = () => {
    changeModalVisible();

    changeState(defaultState);

    if (onCancelModalCallback) {
      onCancelModalCallback();
    }
  };

  const onSave = () => {
    const changedActors = {
      selectedActors,
      addActorsList,
      removeActorsList,
    };

    if (!doNotMakeRequest.includes('saveActors') && localActorsIsChanged) {
      requestUpdateActor({
        actor_type: actorType,
        add_actors_list: addActorsList,
        remove_actors_list: removeActorsList,
        uuid: actorUUID,
      }).then(() => {
        antNotification.defaultSuccess();

        onSaveCallBack?.(changedActors);
      });
    } else {
      onSaveCallBack?.(changedActors);
    }

    onCancel();
  };

  const getBtnModalLabel = () => {
    switch (actorType) {
      case 'user':
      case 'classic_user':
      case 'service':
      case 'newService':
        return capitalizeAndTranslateMsg('54origins.noun.group.1');
      case 'group':
      case 'newGroup':
        return capitalizeAndTranslateMsg('54origins.noun.user.1');
      default:
        return 'actor';
    }
  };

  const getListOfActorsActionBtnLabel = () => {
    switch (actorType) {
      case 'user':
      case 'classic_user':
      case 'service':
      case 'newService':
        return 'add group';
      case 'group':
      case 'newGroup':
        return 'add user';
      default:
        return 'add actor';
    }
  };

  const getModalTitle = () => {
    switch (actorType) {
      case 'user':
      case 'classic_user':
        return 'User groups';
      case 'service':
      case 'newService':
        return 'Service groups';
      case 'group':
      case 'newGroup':
        return 'Users';
      default:
        return 'add actor';
    }
  };

  const showModal = async (e) => {
    stopPropagation(e);

    if (!doNotMakeRequest.includes('getModalSelectedActors')) {
      const actorsData = await getSelectedActorsRequest();

      const {
        actors = [],
      } = actorsData || {};

      changeState({ selectedActors: actors });
    }

    changeModalVisible();
  };

  useEffect(() => {
    if (openModalFromOuter) {
      showModal();
    }
  }, [openModalFromOuter]);

  return (
    <div
      onClick={stopPropagation}
      onDoubleClick={stopPropagation}
    >
      <Modal
        title={modalTitle || getModalTitle()}
        open={modalVisible}
        onOk={onOkModal}
        onCancel={onCancel}
        destroyOnClose
        width="40%"
        centered
        footer={[
          <Button
            key="back"
            onClick={onCancel}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={onSave}
            disabled={!localActorsIsChanged}
          >
            Save
          </Button>,
        ]}
      >
        <>
          <ListOfActorsTags
            actors={selectedActors}
            getRemovedUserCallback={removeSelectedActors}
            isModal
          />
          <ModalListOfActors
            getSelectedActors={getSelectedActors}
            listOfActorsActionBtnLabel={listOfActorsActionBtnLabel || getListOfActorsActionBtnLabel()}
            dataForActorsRequest={getDataForListOfActorsRequest()}
          />
        </>
      </Modal>
      {!hideElements.includes('mainBtn')
           && (
           <Button
             type="default"
             onClick={showModal}
             size="small"
             className="button-primary-outlined actorsModalBtn"
           >
             {!hideElements.includes('btnIcon')
               && (
               <Icon
                 size={1}
                 className="mr-1"
                 path={iconPath}
               />
               )}
             <span>{btnModalLabel || getBtnModalLabel()}</span>
           </Button>
           )}
    </div>
  );
}

export default ActorsModalBtn;

ActorsModalBtn.propTypes = {
  actorType: PropTypes.string,
  actorUUID: PropTypes.string,
  btnModalLabel: PropTypes.string,
  currentActors: PropTypes.array,
  doNotMakeRequest: PropTypes.array,
  getRemovedUserCallback: PropTypes.func,
  hideElements: PropTypes.array,
  iconPath: PropTypes.any,
  listOfActorsActionBtnLabel: PropTypes.string,
  modalTitle: PropTypes.string,
  onCancelModalCallback: PropTypes.func,
  onOkModalCallback: PropTypes.func,
  openModalFromOuter: PropTypes.any,
  updateActorCallback: PropTypes.func,
};
