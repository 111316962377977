import React, { useContext, useEffect, useState } from 'react';
import { get } from 'lodash';

import ActorsList from './ActorsList';

import { ApiContext } from '../../api/ApiContextProvider';

function SelectedGroupsTable({
  selectedGroups,
  selectActor,
  actorUUID,
  selectedActors,
}) {
  // console.log('RENDER')
  const {
    requestGetAllGroups,
  } = useContext(ApiContext);

  const [localActors, setLocalActors] = useState([]);

  const formattedTableData = (data) => data.map((item) => {
    const {
      actor_type: type,
      uuid,
      created,
      root_perms_signature: root,
    } = item;

    const getInfo = (path, value = '') => get(item, `uinfo.${path}`, value);

    const actorData = {
      uuid,
      type,
      created,
      root,
      weight: getInfo('weight', 1),
      comment: getInfo('comment'),
    };

    switch (type) {
      case 'user':
      case 'classic_user':
        return {
          ...actorData,
          name: `${getInfo('first_name')} ${getInfo('last_name')}`,
          groups: getInfo('groups', []),
        };

      case 'group':
        const name = getInfo('group_name', '');

        return {
          ...actorData,
          name,
          default: ['ADMIN', 'DEFAULT', 'BAN'].includes(name),
        };

      case 'service':
        return {
          ...actorData,
          name: getInfo('service_name'),
          groups: getInfo('groups', []),
        };

      default:
        return item;
    }
  });

  const initFunc = async () => {
    const data = {
      uuid: selectedGroups,
    };

    const actorsData = await requestGetAllGroups(data, []);

    const { actors } = actorsData;

    setLocalActors(formattedTableData(actors));

    // console.log('actors', actors)
  };

  useEffect(() => {
    initFunc();
  }, [selectedGroups]);

  return (
    <div>
      <h4>Selected group filters</h4>
      <ActorsList
        withCheckBox={false}
        tableData={localActors}
        selectedActors={selectedActors}
        selectActor={selectActor}
        actorUUID={actorUUID}
      />
    </div>
  );
}

export default SelectedGroupsTable;
