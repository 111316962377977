import {
  mdiClose,
  mdiCodeJson
} from '@mdi/js';
import Icon from '@mdi/react';
import {
  Alert,
  Button,
  Input,
  Modal
} from 'antd';
import PropTypes from 'prop-types';
import React, {
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  antNotification,
  capitalize,
  textToClipboard
} from '../../mainUtils';

const PermissionsButtonJSON = ({
  actorPermissions,
  onSetJSON,
  disabled,
  defaultPermissionUUIDs
}) => {
  const { t } = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [permissionsJSON, setPermissionsJSON] = useState('');
  const [validJSON, setValidJSON] = useState(true);

  const toggleModal = () => setModalVisible((prev) => !prev);

  const permsToJSON = (perms) => {
    const permsJSON = JSON.stringify(perms, null, 4);
    return permsJSON === '[]' ? '' : permsJSON;
  };

  const onCopy = () => {
    textToClipboard(permissionsJSON);
    setValidJSON(true);
    toggleModal();
  };

  const onCancel = () => {
    setPermissionsJSON(permsToJSON(permissions));
    setValidJSON(true);
    toggleModal();
  };

  const onSetPerms = () => {
    try {
      let perms;
      if (permissionsJSON) {
        perms = JSON.parse(permissionsJSON);
        const filteredPerms = perms.filter(({ permaction_uuid: uuid }) => defaultPermissionUUIDs.has(uuid));

        if (perms.length > 0 && filteredPerms.length === 0) {
          antNotification.error('Incorrect permissions (from another actor');
          perms = [];
        }
      } else {
        perms = [];
      }
      if (onSetJSON) {
        onSetJSON(perms);
      }
    } catch {
      antNotification.error('json error');
    }
    toggleModal();
  };

  const onChange = (e) => {
    const { value } = e.target;

    if (value) {
      try {
        JSON.parse(value);
        setValidJSON(true);
      } catch {
        setValidJSON(false);
      }
    } else {
      setValidJSON(true);
    }

    setPermissionsJSON(value);
  };

  useEffect(() => {
    setPermissions(actorPermissions);
    setPermissionsJSON(permsToJSON(actorPermissions));
  }, [actorPermissions]);

  return (
    <>
      <Button
        className={'button-primary-outlined'}
        size={'small'}
        onClick={toggleModal}
        disabled={disabled}
      >
        <Icon
          path={mdiCodeJson}
          size={1}
          className={'mr-1'}
        />
        JSON
      </Button>
      <Modal
        title={`JSON ${capitalize(t(
          'auth.headers.assigned_perms',
          'assigned permissions',
        ))}`}
        open={modalVisible}
        onOk={onCopy}
        onCancel={onCancel}
        width={620}
        destroyOnClose
        closeIcon={<Icon
          path={mdiClose}
          size={1.5}
        />}
        footer={[
          <Button
            key={'cancel'}
            className={'button-secondary-outlined'}
            onClick={onCancel}
          >
            {capitalize(t('auth.buttons.cancel', 'cancel'))}
          </Button>,
          <Button
            key={'copy'}
            className={'button-primary-outlined'}
            onClick={onCopy}
            disabled={!permissionsJSON || !validJSON}
          >
            {capitalize(t('auth.buttons.copy', 'copy'))}
          </Button>,
          <Button
            key={'set'}
            className={'button-primary'}
            disabled={permsToJSON(permissions) === permissionsJSON || !validJSON}
            onClick={onSetPerms}
          >
            {capitalize(t('auth.buttons.set', 'set'))}
          </Button>
        ]}
        okButtonProps={{
          className: 'button-primary'
        }}
        cancelButtonProps={{
          className: 'button-secondary-outlined'
        }}
      >
        {!validJSON && (
          <Alert
            className={'mb-3'}
            message={capitalize(t('auth.headers.error', 'error'))
              .toUpperCase()}
            description={capitalize(t(
              'auth.validation.messages.json_invalid',
              'JSON format invalid',
            ))}
            type={'error'}
            showIcon
          />
        )}
        {modalVisible && (
          <Input.TextArea
            value={permissionsJSON}
            onChange={onChange}
            rows={16}
            autoFocus
          />
        )}
      </Modal>
    </>
  );
};

export default PermissionsButtonJSON;

PermissionsButtonJSON.propTypes = {
  actorPermissions: PropTypes.array.isRequired,
  defaultPermissionUUIDs: PropTypes.object,
  disabled: PropTypes.bool,
  onSetJSON: PropTypes.func.isRequired
};
