import React, {
  memo, useContext, useEffect, useReducer, useState,
} from 'react';
import {
  Button, Col, Row,
} from 'antd';
import Icon from '@mdi/react';
import { mdiReload } from '@mdi/js';
import { useSelector } from 'react-redux';
import { getAllActors } from '../../selectors';

import ColumnsWrapperForBasicActorList from '../ColumnsWrapperForBasicActorList';

import { ApiContext } from '../../../api/ApiContextProvider';
import { antNotification } from '../../../mainUtils';

const ServiceVersionBtn = memo(({
  uuid,
  checkServiceVersion,
  outerUUID,
  serviceBtnCallback,
}) => {
  const [fetching, setFetching] = useReducer((state) => !state, false);
  const [version, setVersion] = useState('');
  const [error, setError] = useState(null);

  const checkVersion = () => {
    setFetching();
    setError(null);

    return checkServiceVersion(uuid)
      .then(({ version }) => {
        setFetching();
        antNotification.success('Success');

        if (version) {
          setVersion(version);
        }
      })
      .catch((error) => {
        setError(error?.message);
        setFetching();
      })
      .finally(() => {
        if (serviceBtnCallback) {
          serviceBtnCallback(uuid);
        }
      });
  };

  useEffect(() => {
    if (outerUUID === uuid) {
      checkVersion().then(() => {}).catch(() => {});
    }
  }, [outerUUID]);

  return (
    <Row>
      <Col span={4}>
        <Button
          className={`mx-auto ${!error && 'button-primary-outlined'}`}
          size="small"
          danger={!!error}
          disabled={fetching}
          onClick={checkVersion}
        >
          {fetching && 'Pending...'}
          {!fetching && !error && !version && 'Check version'}
          {!fetching && version
                    && (
                    <>
                      <Icon
                        className="ml-1"
                        path={mdiReload}
                        size={1}
                      />
                      <span>
                        version
                        {version}
                      </span>
                    </>
                    )}
          {!fetching && error && (
          <>
            <Icon
              className="ml-1"
              path={mdiReload}
              size={1}
            />
            <span>Error</span>
          </>
          )}
        </Button>
      </Col>
      <Col span={20}>
        {error && (
        <div
          className="text-red"
          style={{ whiteSpace: 'pre-wrap' }}
        >
          {error}
        </div>
        )}
      </Col>
    </Row>
  );
});

function ServicesVersioning() {
  const { checkServiceVersion } = useContext(ApiContext);

  const serviceVersioningFetching = useSelector((state) => state.actors.serviceVersioning.fetching);
  const allServices = useSelector(getAllActors);

  const [currentService, setCurrentService] = useState(null);
  const [callbackServiceUUID, setCallbackServiceUUID] = useState(null);

  const checkAllVersions = () => {
    setCallbackServiceUUID(null);

    if (currentService === null) {
      setCurrentService(allServices[0].uuid);
    } else {
      const nextServiceIndex = allServices.findIndex((el) => el.uuid === currentService) + 1;
      const nextServiceUUID = allServices[nextServiceIndex]?.uuid || null;

      setCurrentService(nextServiceUUID);
    }
  };

  const actionFormatter = (cell, row) => (
    <ServiceVersionBtn
      uuid={cell}
      outerUUID={currentService}
      checkServiceVersion={checkServiceVersion}
      serviceBtnCallback={setCallbackServiceUUID}
    />
  );

  const actionForVersion = [{
    dataIndex: 'uuid',
    key: 'actions',
    // className: 'd-flex p-2 pr-4',
    title: '',
    ellipsis: true,
    colSpan: 2,
    onCell: (_, index) => ({ colSpan: 2 }),
    render: actionFormatter,
    shouldCellUpdate: (record, prevRecord) => record.uuid === currentService || currentService === null,
  }];

  useEffect(() => {
    if (currentService && currentService === callbackServiceUUID) {
      checkAllVersions();
    }
  }, [currentService, callbackServiceUUID]);

  return (
    <>
      <Row>
        <Col span={24}>
          <Button
            type="primary"
            size="small"
            onClick={checkAllVersions}
            disabled={serviceVersioningFetching}
          >
            {serviceVersioningFetching ? 'Pending...' : 'Check all service versions'}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <ColumnsWrapperForBasicActorList
            getActorTypes={['service']}
            typeOfColumns="servicesVersions"
            additionalColumns={actionForVersion}
          />
        </Col>
      </Row>
    </>
  );
}

export default ServicesVersioning;
