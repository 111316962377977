import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Tag,
} from 'antd';
import { get, isNull } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import TableTitleWrapper from '../../components/TableTitleWrapper';
import AntTable from '../../components/AntTable';
import AntDesignSearchBox54origins from '../../54origins/components/AntDesignSearchBox54origins';

import { getLocale } from '../../locale/selectors';
import { capitalize } from '../../mainUtils';
import { getNameForActor } from '../../54origins/utils54origins';

import { ApiContext } from '../../api/ApiContextProvider';

function ModalListOfActors({
  doNotMakeRequest = [],
  addActorsAfterRequest = [],
  dataForActorsRequest,
  removeActorsAfterRequest = [],
  getSelectedActors,
  listOfActorsActionBtnLabel = 'add to actor',
}) {
  const { getActors } = useContext(ApiContext);

  const { t } = useTranslation();

  const locale = useSelector(getLocale) || 'en';

  const initState = {
    tableData: [],
    currentPage: 1,
    pageLimit: 10,
    actorsCount: 0,
    initSearch: false,
    loading: false,
    orderRules: {
      order_by_column: 'created',
      order_by_rule: 'desc',
    },
  };
  const [state, setState] = useState(initState);

  const {
    tableData,
    currentPage,
    pageLimit,
    actorsCount,
    searchValue,
    initSearch,
    loading,
    orderRules,
  } = state;

  const changeState = (params) => setState((prev) => ({ ...prev, ...params }));

  const pageCountLocalActors = Math.ceil(addActorsAfterRequest.length / pageLimit);

  const changeSort = (param, sortOrder) => {
    changeState({
      orderRules: {
        order_by_column: param,
        order_by_rule: sortOrder === 'ascend' ? 'asc' : 'desc',
      },
    });
  };

  const onChangeActorsPagination = (currentPage) => changeState({ currentPage });

  const onShowSizeChange = (current, size) => changeState({ pageLimit: size });

  const onSearch = (value) => changeState({ searchValue: value });

  const getActorsAndCount = async () => {
    changeState({ loading: true });

    const customOffset = pageLimit * (currentPage - 1 - pageCountLocalActors);

    const finalData = {
      ...orderRules,
      ...dataForActorsRequest,
      limit: pageLimit,
      offset: customOffset,
    };

    if (searchValue) {
      finalData.search_data = {
        value: searchValue,
        ignore_case: true,
        fields:
            {
              base: ['uuid'],
              uinfo: ['first_name', 'last_name', 'group_name', 'service_name'],
            },
      };
    }

    console.log('finalData', finalData);

    const actorsRequest = await getActors(finalData, []);

    const { actors = [], total = 0 } = actorsRequest || {};

    let modifiedActors = isNull(actors) ? [] : actors;
    modifiedActors = modifiedActors.filter((item) => !removeActorsAfterRequest.includes(item.uuid));

    // console.log('actors?.total actors?.total', modifiedActors)

    changeState({
      actorsCount: total,
      tableData: modifiedActors,
      loading: false,
    });
  };

  // const onSelectActors = (value, row) => {
  //   if (value === 'all') {
  //     setSelectAllCheckBoxFlag(!selectAllCheckBoxFlag)
  //     setCheckBoxSelectedActors((prev) => {
  //       if (prev.size !== tableData.length) {
  //
  //         setCheckBoxSelectedActorsRows([...tableData])
  //
  //         return new Set(tableData.map(({ uuid }) => uuid));
  //       } else {
  //         setCheckBoxSelectedActorsRows([])
  //
  //         return new Set();
  //       }
  //     });
  //   } else {
  //     setCheckBoxSelectedActors((prev) => {
  //       if (prev.has(value)) {
  //         prev.delete(value);
  //
  //         setCheckBoxSelectedActorsRows(selectedCheckBoxActorsRows.filter(({uuid}) => uuid !== value))
  //       } else {
  //         prev.add(value);
  //
  //         setCheckBoxSelectedActorsRows([...selectedCheckBoxActorsRows, row])
  //       }
  //       return new Set(prev);
  //     });
  //   }
  // };

  const onRow = (record) => ({
    onClick: () => {
      // onSelectActors(uuid, record)
      getSelectedActors([record]);
    },
  });

  // const titleDeleteCheckBox = (
  //     <Checkbox
  //         onClick={(e) => {e.stopPropagation()}}
  //         onChange={() => {
  //           onSelectActors('all')
  //         }}
  //         checked={selectAllCheckBoxFlag}
  //     />
  // );

  // const formatDeleteCheckBox = (cell, row) => (
  //     <Checkbox
  //         onClick={(e) => e.stopPropagation()}
  //         onChange={() => onSelectActors(cell, row)}
  //         checked={selectedCheckBoxActors.has(cell)}
  //     />
  // );

  const formatActorName = (cell, row) => {
    const { actor_type, uinfo } = row || {};

    const getName = () => {
      switch (actor_type) {
        case 'service':
          return getNameForActor(row);
        case 'user':
        case 'classic_user':
          const { isRoot, isAdmin } = uinfo;
          const tagName = isRoot ? 'root' : 'default';
          return (
            <>
              <span>
                {getNameForActor(row)}
              </span>
              {isRoot || isAdmin
                  && (
                  <Tag className={`tag-${isRoot ? 'purple' : 'gray'} ml-2`}>
                    {t(`auth.headers.${tagName}`, tagName).toUpperCase()}
                  </Tag>
                  )}
            </>
          );
        case 'group':
          return getNameForActor(row);
        default:
          return 'actor type not found';
      }
    };

    return (
      <div className="d-flex align-items-center">
        {getName()}
        {/* {( */}
        {/*    isRoot || isDefault */}
        {/* ) && ( */}
        {/*    <Tag className={`tag-${isRoot ? 'purple' : 'gray'} ml-2`}> */}
        {/*        {t(`auth.headers.${tagName}`, tagName).toUpperCase()} */}
        {/*    </Tag> */}
        {/* )} */}
      </div>
    );
  };

  const formatActorType = (cell) => (
    <Tag className={`tag-${cell}`}>
      {capitalize(t(`auth.headers.${cell}`, cell))}
    </Tag>
  );

  const formatActions = (cell, row) => (
    <div>
      <Button
        className="button-access-outlined px-2"
        size="small"
        onClick={() => getSelectedActors([row])}
      >
        {listOfActorsActionBtnLabel}
      </Button>
    </div>
  );

  const columns = [
    {
      dataIndex: 'uuid',
      // colSpan: 1,
      key: 'actions',
      className: 'p-2 pr-4',
      title: '',
      render: formatActions,
    },
    // {
    //   dataIndex: 'uuid',
    //   key: 'delete_checkbox',
    //   className: 'p-2 pl-4',
    //   title: titleDeleteCheckBox,
    //   render: formatDeleteCheckBox
    // },
    {
      dataIndex: 'name',
      key: 'name',
      className: 'p-2',
      title: (
        <AntDesignSearchBox54origins
          onSearch={onSearch}
          placeholder="input search text"
        />
      ),
      sorter: (a, b, sortOrder) => changeSort('title', sortOrder),
      render: formatActorName,
    },
    {
      dataIndex: 'actor_type',
      key: 'actor_type',
      title: (
        <TableTitleWrapper minWidth={40}>
          {capitalize(t('auth.headers.type', 'type'))}
        </TableTitleWrapper>
      ),
      sorter: (a, b, sortOrder) => changeSort('actor_type', sortOrder),
      className: 'p-2',
      align: 'left',
      render: formatActorType,
    },
    {
      dataIndex: 'created',
      key: 'created',
      title: (
        <TableTitleWrapper minWidth={120}>
          {capitalize(t('auth.headers.created', 'created'))}
        </TableTitleWrapper>
      ),
      className: 'p-2',
      textWrap: 'word-break',
      sorter: (a, b, sortOrder) => changeSort('created', sortOrder),
      render: (cell) => capitalize(moment(cell)
        .locale(locale)
        .format('MMM D, LT')),
      defaultSortOrder: 'descend',
    },
  ];

  const rowSelection = {
    columnWidth: 40,
    selectedRowKeys: [],
  };

  const calculateTotal = () => actorsCount + (pageCountLocalActors * pageLimit);

  // const addOrRemoveSelectedUsers = () => {
  //   getSelectedActors(selectedCheckBoxActorsRows)
  //   setCheckBoxSelectedActors(new Set())
  //   setSelectAllCheckBoxFlag(false)
  // }

  const getActorsIfEmpty = async () => {
    onChangeActorsPagination(Math.abs(currentPage - 1));
    await getActorsAndCount();
  };

  useEffect(() => {
    if (!doNotMakeRequest.includes('getActors')
    // || dataForActorsRequest.uuid.length === 0
    ) {
      getActorsAndCount();

      if (searchValue && !initSearch) {
        // console.log('FIRST SEARCH')
        changeState({
          currentPage: 1,
          initSearch: true,
        });
      } else if (!searchValue) {
        // console.log('EMPTY SEARCH')
        changeState({ initSearch: false });
      }
    }
  }, [
    currentPage,
    pageLimit,
    searchValue,
    JSON.stringify(orderRules),
    JSON.stringify(dataForActorsRequest),
  ]);

  useEffect(() => {
    if (tableData.length === 0 && currentPage !== 1) {
      getActorsIfEmpty();
    }
  }, [tableData.length]);

  return (
    <div>
      {/* <Row type={'flex'} className={'mb-2'}> */}
      {/*  <Col span={24} className={'d-flex'}> */}
      {/*    {selectedCheckBoxActors.size !== 0 && */}
      {/*    <Button */}
      {/*        className={`button-access-outlined px-2`} */}
      {/*        size={'small'} */}
      {/*        onClick={addOrRemoveSelectedUsers} */}
      {/*    > */}
      {/*      {'add selected'} */}
      {/*    </Button> */}
      {/*    } */}
      {/*  </Col> */}
      {/* </Row> */}
      <AntTable
            // paginationPageSize={calculatePageSize()}
        columns={columns}
        current={currentPage}
        data={tableData}
        disablePagination={false}
        loading={loading}
        onChangePagination={onChangeActorsPagination}
        onRow={onRow}
        onShowSizeChange={onShowSizeChange}
        paginationPageSize={pageLimit}
        paginationSize="default"
        rowSelection={rowSelection}
        total={calculateTotal()}
      />
    </div>
  );
}

export default ModalListOfActors;

ModalListOfActors.propTypes = {
  selectedCheckBoxActors: PropTypes.any,
  tableData: PropTypes.array,
};
