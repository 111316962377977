import PropTypes from 'prop-types';
import React, {
  memo, useContext, useEffect, useReducer, useState,
} from 'react';
import { Button } from 'antd';
import { mdiFormatListGroup } from '@mdi/js';
import Icon from '@mdi/react';

import ActorsModalBtn from './ActorsModalBtn';
import ListOfActorsTags from './ListOfActorsTags';

import { ApiContext } from '../../api/ApiContextProvider';

function ListOfCurrentActorsAndChooseActors({
  actorData,
  btnModalLabel,
  hideModalElements,
  iconModalPath,
  hideElements = [],
  onSaveCallBack,
  updateInitFromOuter,
  editMode,
}) {
  const { getActors } = useContext(ApiContext);

  const INITIAL_LIMIT_OF_ACTORS = 10;

  const [localActors, setLocalActors] = useState([]);
  const [hideCollapse, setHideCollapse] = useState(true);
  const [lengthOfActors, setLengthOfActors] = useState(0);
  const [collapseFlag, setCollapseFlag] = useReducer((state) => !state, false);
  const [updateActorFlag, setUpdateActorFlag] = useReducer((state) => !state, false);

  const {
    actor_type: actorType,
    uuid: actorUUID,
    uinfo: {
      groups = [],
    } = {},
  } = actorData || {};
  // console.log('actorType', actorType)
  const getActorsRequest = (params) => {
    const data = {
      ...params,
    };

    switch (actorType) {
      case 'user':
      case 'classic_user':
      case 'service':
        if (groups.length !== 0) {
          data.actor_type = ['group'];
          data.uuid = groups;

          return getActors(data, []);
        }
        break;
      case 'group':
        if (actorUUID) {
          data.uinfo = {};
          data.uinfo.groups = [actorUUID];

          return getActors(data, []);
        }

        break;
      default:
        return [];
    }
  };

  const getActorsAndChangeState = async (params) => {
    const actorsData = await getActorsRequest(params);

    const {
      actors = [],
      total = 0,
    } = actorsData || {};

    // console.log('actors', actors)

    setLocalActors(actors);
    setLengthOfActors(total);
    setHideCollapse(total < INITIAL_LIMIT_OF_ACTORS);
  };

  const changeCollapse = () => {
    if (collapseFlag) {
      initFunc();
    } else {
      getActorsAndChangeState();
    }

    setCollapseFlag();
  };

  const onSaveCallBackAndSetLocalActors = (actors) => {
    const { selectedActors } = actors;
    setLocalActors(selectedActors);

    onSaveCallBack?.(actors);
  };

  const initFunc = () => {
    // console.log('initFunc initFunc')

    const params = {
      limit: INITIAL_LIMIT_OF_ACTORS,
      offset: 0,
    };

    getActorsAndChangeState(params);

    if (updateActorFlag) {
      setUpdateActorFlag();
    }
  };

  useEffect(() => {
    initFunc();
  }, [
    actorUUID,
    JSON.stringify(groups),
    updateInitFromOuter,
  ]);

  const doNotMakeRequest = editMode ? ['saveActors'] : [];

  return (
    <>
      <ListOfActorsTags
        actors={localActors}
      />
      <div className="d-flex mt-3">
        {(!hideCollapse && !hideElements.includes('collapse')) && (
        <Button
          onClick={changeCollapse}
          type="default"
          size="small"
          className="button-primary-outlined mr-3"
        >
          <Icon path={mdiFormatListGroup} size={1} />
          Show
          {' '}
          {collapseFlag ? `only ${INITIAL_LIMIT_OF_ACTORS}` : `all ${lengthOfActors}`}
          {' '}
          groups
        </Button>
        )}
        <ActorsModalBtn
          actorType={actorType}
          actorUUID={actorUUID}
          btnModalLabel={btnModalLabel}
          currentActors={groups}
          hideElements={hideModalElements}
          iconPath={iconModalPath}
          listOfActorsActionBtnLabel="add group"
          onSaveCallBack={onSaveCallBackAndSetLocalActors}
          doNotMakeRequest={doNotMakeRequest}
        />
      </div>
    </>
  );
}

// export default ListOfCurrentActorsAndChooseActors;
export default memo(ListOfCurrentActorsAndChooseActors);

ListOfCurrentActorsAndChooseActors.propTypes = {
  actorData: PropTypes.any,
  updateInitFromOuter: PropTypes.any,
  btnModalLabel: PropTypes.string,
  hideElements: PropTypes.array,
  hideModalElements: PropTypes.array,
  iconModalPath: PropTypes.string,
  updateActorCallback: PropTypes.func,
};
