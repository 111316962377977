import axios from 'axios';
import i18n from '../i18n';

import { AuthHelper } from '../auth/utils';
import { logout, unauthorized } from '../actors/actions/actorsActions';
import { capitalize, antNotification } from '../mainUtils';
import { parseErrorFromRequest54origins } from '../54origins/utils54origins';

const localServer = 'http://192.168.1.167:5005';

const devServer = 'https://auth.04releasetest.54origins.com';

export const urlAPI = process.env.NODE_ENV === 'development' ? devServer : window.location.origin;

const handleError = (error) => {
  const {
    status,
    message,
  } = parseErrorFromRequest54origins(error) || {};

  switch (status) {
    case 400:
      if (message) {
        antNotification.error(message);
      }
      break;
    case 401:
      unauthorized(message);
      logout();
      break;
    case 403:
      antNotification.error(capitalize(i18n.t('auth.notifications.forbidden', 'access rights denied')));
      break;
    case 430:
      break;

    default:
      break;
  }
};

class AxiosAPI {
  allSources = {};

  getSourceToken = (requestType) => {
    const source = this.allSources[requestType] || axios.CancelToken.source();
    this.allSources[requestType] = source;

    return source.token;
  };

  resetSource = (requestType) => {
    delete this.allSources[requestType];
  };

  request = (endpoint, types = [], data, options = {}, method) => {
    const {
      aborting,
      doNotCheckError,
      onCancel,
      onFailure,
      onFinished,
      onSuccess,
      url,
      withoutSessionToken,
    } = options;
    const [REQUEST, SUCCESS, FAILURE, CANCELED = null] = types;
    let headers = {
      'Content-Type': data instanceof FormData ? 'multipart/form-data' : 'application/json',
      'Accept-Language': localStorage.getItem('locale') || 'en',
    };

    if (!withoutSessionToken) {
      headers = {
        ...headers,
        'Session-Token': AuthHelper.getToken(),
      };
    }

    let config = {
      url: `${url || urlAPI}/${endpoint}`,
      method,
      data,
      headers,
    };

    if (aborting) {
      config = {
        ...config,
        cancelToken: this.getSourceToken(REQUEST),
      };
    }

    return (dispatch, getState) => {
      if (REQUEST) {
        dispatch({ type: REQUEST });
      }

      const state = getState();

      axios
        .request(config)
        .then((response) => {
          if (aborting) {
            this.resetSource(REQUEST);
          }

          if (SUCCESS) {
            dispatch({
              type: SUCCESS.type || SUCCESS,
              payload: SUCCESS.payload
                ? SUCCESS.payload(response.data, state, response.status)
                : response.data,
            });
          }

          if (onSuccess) {
            onSuccess(response.data, state, response.status);
          }

          if (onFinished) {
            onFinished(response.data, state, response.status);
          }
        })
        .catch((error) => {
          const checkFailure = () => {
            if (onFailure) {
              onFailure(parseErrorFromRequest54origins(error));
            }

            if (FAILURE) {
              dispatch({
                type: FAILURE.type || FAILURE,
                payload: FAILURE.payload
                  ? FAILURE.payload(parseErrorFromRequest54origins(error), state)
                  : parseErrorFromRequest54origins(error),
              });
            }
          };

          if (!doNotCheckError) {
            handleError(error);
          }

          if (axios.isCancel) {
            // console.log('error', error);
            if (onCancel) {
              onCancel(parseErrorFromRequest54origins(error));
            }

            if (CANCELED) {
              dispatch({
                type: CANCELED.type || CANCELED,
              });
            }

            if (!onCancel && !CANCELED) {
              checkFailure();
            }
          } else {
            checkFailure();
          }
        });
    };
  };

  get = (endpoint, types, options) => this.request(endpoint, types, null, options, 'get');

  post = (endpoint, types, data, options) => this.request(endpoint, types, data, options, 'post');

  delete = (endpoint, types, data, options) => this.request(endpoint, types, data, options, 'delete');

  put = (endpoint, types, data, options) => this.request(endpoint, types, data, options, 'put');

  abort = (abortMessage = '', abortType) => {
    const onAbort = (requestType) => {
      const source = this.allSources[requestType];

      if (source) {
        source.cancel(abortMessage);
      }
    };

    if (abortType) {
      if (this.allSources[abortType]) {
        onAbort(abortType);
      }
    } else {
      Object.keys(this.allSources).forEach((key) => {
        onAbort(key);
      });
    }
  };
}

const axiosRequest = new AxiosAPI();

export default axiosRequest;

export const axiosRequestPost = axiosRequest.post;
export const axiosRequestGet = axiosRequest.get;
export const axiosRequestPut = axiosRequest.put;
export const axiosRequestDelete = axiosRequest.delete;
export const axiosRequestAbort = axiosRequest.abort;
