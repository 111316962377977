import React, { useEffect, useState } from 'react';
import { Card, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import {
  mdiCertificateOutline,
  mdiInformationOutline,
  mdiKeyOutline,
  mdiRefresh,
  mdiShieldCheckOutline,
} from '@mdi/js';
import { useSelector } from 'react-redux';

import PageWrapper from '../components/PageWrapper';
import SyncServicesPageCopy from '../syncServices/components/SyncServicesPage';
import BiomeInfoPage from './BiomeInfoPage';
import CertificatesPage from '../certificates/components/CertificatesPage';
import SecurityPolicyComponent from '../securityPolicy/components/SecurityPolicyPage';
import ServicesKeyPairs from '../actors/components/service/ServicesKeyPairs';
import ServicesSessions from '../actors/components/service/ServicesSessions';
import ServicesVersioning from '../actors/components/service/ServicesVersioning';

import { capitalize, paramsURL } from '../mainUtils';

function BiomeDashboard() {
  const { t } = useTranslation();

  const dataFromUrl = useSelector(paramsURL.get);

  const { tab } = dataFromUrl;

  const [activeKey, setActiveKey] = useState('synchronization');

  const getLabelAndTranslate = (label, iconPath, translatePath = '') => (
    <span>
      <Icon path={iconPath} size={1.3} />
      <span className="ml-1">{capitalize(t(translatePath, label))}</span>
    </span>
  );

  const onTabClick = (key) => {
    paramsURL.set({ tab: key });
    setActiveKey(key);
  };

  const items = [
    {
      label: getLabelAndTranslate('Biome info', mdiInformationOutline, 'auth.messages.biome_info'),
      key: 'info',
      children: <BiomeInfoPage />,
    },
    {
      label: getLabelAndTranslate('Synchronization', mdiRefresh, '54origins.noun.synchronization'),
      key: 'synchronization',
      children: <SyncServicesPageCopy />,
    },
    {
      label: getLabelAndTranslate('Services Keypairs', mdiKeyOutline, 'auth.messages.services_keypairs'),
      key: 'serviceskeypairs',
      children: <ServicesKeyPairs />,
    },
    {
      label: getLabelAndTranslate('services sessions', '', 'auth.messages.services_sessions'),
      key: 'servicessessions',
      children: <ServicesSessions />,
    },
    {
      label: getLabelAndTranslate('versioning', '', '54origins.noun.versioning'),
      key: 'versioning',
      children: <ServicesVersioning />,
    },
    {
      label: getLabelAndTranslate('SSL certificates', mdiCertificateOutline, 'auth.messages.ssl_certificates'),
      key: 'ssl',
      children: <CertificatesPage />,
    },
    {
      label: getLabelAndTranslate('Security policy', mdiShieldCheckOutline, 'auth.messages.security_policy'),
      key: 'secpolicy',
      children: <SecurityPolicyComponent />,
    },
  ];

  useEffect(() => {
    if (!tab) {
      paramsURL.set({ tab: 'synchronization' });
    } else {
      setActiveKey(tab);
    }
  }, []);

  return (
    <PageWrapper
      title={capitalize(t('auth.pages.biome_info', 'biome dashboard'))}
    >
      <Card className="card">
        <Tabs
          onTabClick={onTabClick}
          defaultActiveKey="synchronization"
          activeKey={activeKey}
          destroyInactiveTabPane
          items={items}
        />
      </Card>
    </PageWrapper>
  );
}

export default BiomeDashboard;
