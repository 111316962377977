import PropTypes from 'prop-types';
import React from 'react';
import { capitalize } from '../../mainUtils';
import moment from 'moment';
import AntTable from '../../components/AntTable';
import { useSelector } from 'react-redux';
import { getLocale } from '../../locale/selectors';
import { useTranslation } from 'react-i18next';
import { Button, Card, Col, Row } from 'antd';
import { getPolicyDocuments } from '../selectors';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';


const PolicyHistoryFile = ({
  onSelectDocument,
  onResetSelectedDocument,
  selectedID
}) => {
  const { t } = useTranslation();

  const locale = useSelector(getLocale) || 'en';
  const securityPolicyDataList = useSelector(getPolicyDocuments);

  const onRow = (data) => {
    return {
      onClick: (e) => {
        e.stopPropagation();
        onSelectDocument(data);
      }
    };
  };

  const columns = [
    {
      dataIndex: 'created',
      key: 'created',
      className: 'p-2 pl-3',
      title: capitalize(t('auth.headers.created', 'created')),
      render: (cell) => capitalize(moment(cell)
        .locale(locale)
        .format('MMM D, LT'))
    },
    {
      dataIndex: 'security_policy_name',
      key: 'security_policy_name',
      ellipsis: true,
      className: 'p-2',
      title: capitalize(t('auth.headers.name', 'name'))
    },
    {
      dataIndex: 'security_policy_text',
      key: 'security_policy_text',
      className: 'p-2',
      // width: '100%',
      title: capitalize(t('auth.headers.policy_text', 'content')),
      ellipsis: true,
      render: (cell) => (
        <>
          {cell}
        </>
      )
    },
    {
      dataIndex: 'username',
      key: 'username',
      className: 'p-2 pr-3',
      title: capitalize(t('auth.headers.user', 'user'))
    }
  ];

  const rowSelection = {
    columnWidth: 130,
    selectedRowKeys: selectedID ? [selectedID.toString()] : []
  };


  return (
    <Card
      className={'card border-volcano'}
    >
      <Row>
        <Col flex={'auto'}>
          <h5 className={'header-primary mb-4'}>
            {capitalize(t('auth.headers.changes_history', 'changes history'))}:
          </h5>
        </Col>
        <Col>
          <Button
            className={'button-warning-outlined'}
            size={'small'}
            onClick={onResetSelectedDocument}
            disabled={!selectedID}
          >
            <Icon path={mdiClose} size={1} className={'mr-2'}/>
            {capitalize(t('auth.buttons.reset', 'reset'))}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <AntTable
            data={securityPolicyDataList}
            columns={columns}
            rowKey={'id'}
            rowClassName={'table-selectable'}
            onRow={onRow}
            rowSelection={rowSelection}
            paginationPageSize={10}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default PolicyHistoryFile;

PolicyHistoryFile.propTypes = {
  onResetSelectedDocument: PropTypes.func.isRequired,
  onSelectDocument: PropTypes.func.isRequired,
  selectedID: PropTypes.number
};
