import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'antd';

function PageWrapper({
  title,
  children,
}) {
  return (
    <div className="dt-content-wrapper">
      <div className="dt-content">
        {title && (
          <Row>
            <Col span={24}>
              <h1 className="header-page">
                {title}
              </h1>
            </Col>
          </Row>
        )}
        {children}
      </div>
    </div>
  );
}

export default PageWrapper;

PageWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string,
};
