import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Button, Card, Col, Form, Row } from 'antd';
import { capitalize } from '../../mainUtils';
import IStickyBox from 'react-sticky-box';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiTrashCanOutline } from '@mdi/js';
import DeleteButton from '../../components/DeleteButton';
import CertificateForm from './CertificateForm';


const CertificateInfo = ({
  certificate,
  onSuccessDelete
}) => {
  const { t } = useTranslation();
  const [certificateForm] = Form.useForm();

  useEffect(() => {
    certificateForm.setFieldsValue({
      ...certificate
    });
  }, [certificate.id]);


  return (
    <IStickyBox offsetTop={16} offsetBottom={16}>
      <Card className={'card'}>
        <Row>
          <Col flex={'none'}>
            <h4 className={'header-primary'}>
              {capitalize(t('auth.headers.certificate_info', 'certificate info'))}
            </h4>
          </Col>
          <Col flex={'auto'} className={'d-flex justify-content-end'}>
            <DeleteButton
              targetType={'certificate'}
              onSuccess={onSuccessDelete}
              data={{ id: certificate.id }}
            >
              <Button
                className={'button-danger-outlined'}
                size={'small'}
              >
                <Icon path={mdiTrashCanOutline} size={1} className={'mr-1'} />
                {capitalize(t('auth.buttons.remove_certificate', 'remove certificate'))}
              </Button>
            </DeleteButton>
          </Col>
        </Row>
        <hr className={'my-4'} />
        <Row>
          <Col span={24}>
            <CertificateForm
              form={certificateForm}
              disabled
            />
          </Col>
        </Row>
      </Card>
    </IStickyBox>
  );
};

export default CertificateInfo;

CertificateInfo.propTypes = {
  certificate: PropTypes.object.isRequired,
  onSuccessDelete: PropTypes.func.isRequired
};
