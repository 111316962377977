import React, { useContext, useEffect, useState } from 'react';
import {
  Button, Card, Col, Row, Spin, Tag, Tooltip,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Icon from '@mdi/react';
import {
  mdiCheckboxMarkedCircleOutline,
  mdiChevronRight,
  mdiInformationOutline,
  mdiRefresh,
  mdiTrashCanOutline,
} from '@mdi/js';
import { get, capitalize } from 'lodash';

import AntTable from '../../components/AntTable';
import LogsInfoComponent from './LogsInfoComponent';
import FilterLogs from './FilterLogs';

import { getLocale } from '../../locale/selectors';
import { ApiContext } from '../../api/ApiContextProvider';
import TagForLogComponent from './TagForLogComponent';

function LogsPage() {
  const { t } = useTranslation();
  const { requestGetAllActors, requestGetAllGroups } = useContext(ApiContext);

  const locale = useSelector(getLocale) || 'en';

  const [logsData, setLogsData] = useState([]);
  const [currentView, setCurrentView] = useState('');
  const [currentLog, setCurrentLog] = useState(null);

  const switchView = (view) => {
    setCurrentView(view || '');
  };

  const formatTime = (cell) => {
    const getTime = moment(cell).locale(locale).format('YYYY-MM-DD hh:mm:ss');

    return (
      <span className="text-primary text-size-4">
        {getTime}
      </span>
    );
  };

  const formatAction = (cell) => <TagForLogComponent typeOfTag={cell} />;

  const formatType = (row) => {
    const actorType = get(row, 'actor.actor_type') || get(row, 'actor_type') || get(row, 'previous.actor_type');

    const title = capitalize(t(
      `auth.headers.${actorType}`,
      actorType,
    ));

    return (
      <Tag className={`tag-${actorType}`}>
        {title}
      </Tag>
    );
  };

  const formatName = (cell) => {
    switch (get(cell, 'actor_type')) {
      case 'classic_user':
      case 'user':
        const firstName = get(cell, 'first_name') || get(cell, 'uinfo.first_name');
        const lastName = get(cell, 'last_name') || get(cell, 'uinfo.last_name');
        return `${firstName} ${lastName}`;
      case 'group':
        return get(cell, 'group_name') || get(cell, 'uinfo.group_name', '');
      case 'service':
        return get(cell, 'service_name') || get(cell, 'uinfo.service_name', cell);

      default:
        return '';
    }
  };

  const formatUpdater = (cell, row) => (
    <div>
      <Tooltip
        title={capitalize(t(
          'auth.buttons.detail_actor_info',
          'click for detailed information about the actor',
        ))}
      >
        <Button
          onClick={() => {
            setCurrentLog(row);
            switchView('UpdaterInfo');
          }}
          className="button-primary-link"
          size="small"
        >
          {formatName(cell)}

          <Icon className="ml-1" path={mdiInformationOutline} size={1} />
        </Button>
      </Tooltip>
    </div>
  );

  const formatViewButton = (uuid, log) => (
    <Button
      className="button-primary-outlined"
      size="small"
      onClick={() => {
        setCurrentLog(log);
        switchView('info');
      }}
    >
      {capitalize(t('auth.buttons.detail', 'detail'))}
      <Icon path={mdiChevronRight} size={1} className="ml-1" />
    </Button>
  );

  const columns = [
    {
      dataIndex: 'created',
      key: 'created',
      className: 'p-1',
      title: capitalize(t('auth.headers.time', 'time')),
      render: formatTime,
    },
    {
      title: capitalize(t('auth.headers.action', 'action')),
      dataIndex: 'action',
      key: 'action',
      className: 'p-3',
      render: formatAction,
    },
    {
      title: capitalize(t('auth.headers.actor', 'actor')),
      dataIndex: 'actor',
      key: 'actor',
      className: 'p-3',
      ellipsis: true,
      render: (cell, row) => {
        const data = get(row, 'action') === 'delete'
          ? get(row, 'previous')
          : cell;
        return <span className="colorTextGrey">{formatName(data)}</span>;
      },
    },
    {
      title: capitalize(t('auth.headers.type', 'type')),
      dataIndex: 'type',
      key: 'type',
      className: 'p-3',
      render: (cell, row) => formatType(row),
    },
    {
      title: capitalize(t('auth.headers.by', 'by')),
      dataIndex: 'updater',
      key: 'updater',
      className: 'p-3',
      render: formatUpdater,
    },
    {
      dataIndex: 'uuid',
      key: 'uuid',
      className: 'p-3',
      title: '',
      align: 'right',
      render: formatViewButton,
    },
  ];

  useEffect(() => {
    requestGetAllActors();
    requestGetAllGroups();
  }, []);

  return (
    <div className="dt-content-wrapper">
      <div className="dt-content">
        <Row>
          <Col span={24}>
            <h1 className="header-page">
              {capitalize(t(
                'auth.pages.logs',
                'logs',
              ))}
            </h1>
          </Col>
        </Row>
        <Row gutter={[24, 16]}>
          <Col span={14}>
            <Card
              className="card"
              loading={false}
            >
              <div className="mb-4">
                <FilterLogs
                  setLogsData={setLogsData}
                />
              </div>
              <Spin spinning={false}>
                <AntTable
                  data={logsData}
                  columns={columns}
                  rowKey="id"
                />
              </Spin>
            </Card>

          </Col>
          {(
            currentView && currentLog
          ) && (
            <Col span={10}>
              <LogsInfoComponent
                logData={currentLog}
                currentViewInfoFromEntity={currentView === 'info'}
                // formatAction={formatAction}
                formatType={formatType}
              />
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
}

export default LogsPage;
