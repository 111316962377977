import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button, Card, Col, Form, Radio, Row, Spin, Tabs,
} from 'antd';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import {
  mdiAccount,
  mdiCheckboxMultipleMarkedCircleOutline,
  mdiCog,
  mdiHexagonMultiple,
  mdiShieldAccount,
} from '@mdi/js';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import moment from 'moment';
import IStickyBox from 'react-sticky-box';

import InfoBlock from '../../components/InfoBlock';
import ServiceInfoFormItems from './infoForms/ServiceInfoFormItems';
import GroupInfoFormItems from './infoForms/GroupInfoFormItems';
import UserInfoFormItems from './infoForms/UserInfoFormItems';
import ActorFormWrapper from './infoForms/ActorFormWrapper';

import { antNotification, capitalize } from '../../mainUtils';
import { isActorCreatedFetching } from '../selectors';
import { ApiContext } from '../../api/ApiContextProvider';
import { getAuthBiomeInfo, getAuthServiceInfo } from '../../auth/selectors';

function ActorCreate({
  switchView,
  onSuccess,
}) {
  const { t } = useTranslation();
  const [actorForm] = Form.useForm();

  // console.log('actorForm', actorForm)

  const {
    requestCreateActor,
    requestUpdateActor,
    downLoadBackupFileRequest,
  } = useContext(ApiContext);

  const creatingFetching = useSelector(isActorCreatedFetching);
  const authBiomeInfo = useSelector(getAuthBiomeInfo);
  const authServiceInfo = useSelector(getAuthServiceInfo);

  const [selectedActorType, setSelectedActorType] = useState('classic_user');

  const tMsg = (path, defaultValue) => capitalize(t(`auth.validation.messages.${path}`, defaultValue || path));
  const getTranslate = (path, defaultValue) => capitalize(t(path, defaultValue || path));

  const validateMessages = {
    required: tMsg('required', 'is required'),
    string: {
      len: tMsg('len', 'must be exactly ${len} characters'),
      min: tMsg('min', 'must be at least ${min} characters'),
      max: tMsg('max', 'cannot be longer than ${max} characters'),
    },
    types: {
      email: tMsg('email', 'is not a valid email'),
      number: tMsg('number', 'must be a number type'),
      url: tMsg('url', 'is not a valid URL'),
    },
  };

  const rules = {
    user: {
      first_name: [{ max: 200 }, { required: true }],
      last_name: [{ max: 200 }, { required: true }],
      email: [{ type: 'email' }, { required: true }],
      password: [{ min: 4 }, { max: 200 }, { required: true }],
    },
    group: {
      group_name: [{ min: 1 }, { max: 200 }, { required: true }],
      weight: [{ type: 'number' }, { required: true }],
    },
    service: {
      service_name: [{ min: 1 }, { max: 200 }, { required: true }],
      service_domain: [{ type: 'url' }, { required: true }],
      uuid: [{ pattern: /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i, message: 'UUID is not valid' }],
      initial_key: [{ len: 130 }, { required: true }],
    },
  };

  const actorTypes = [
    {
      value: 'classic_user',
      label: getTranslate('auth.headers.classic_user', 'classic_user'),
      color: 'blue',
      iconPath: mdiAccount,
    },
    {
      value: 'user',
      label: getTranslate('auth.headers.user', 'user'),
      color: 'geekblue',
      iconPath: mdiShieldAccount,
    },
    {
      value: 'group',
      label: getTranslate('auth.headers.group', 'group'),
      color: 'red',
      iconPath: mdiHexagonMultiple,
    },
    {
      value: 'service',
      label: getTranslate('auth.headers.service', 'service'),
      color: 'orange',
      iconPath: mdiCog,
    },
  ];

  const handleChangeActorType = (e) => {
    const { value } = e.target;
    setSelectedActorType(value);
  };

  const formProps = {
    editMode: true,
    creating: true,
    actorType: selectedActorType,
    actorForm,
    validateMessages,
  };

  const checkNewAddedActorsAndUpdateUser = (actorUUID) => {
    const removedActorList = actorForm.getFieldValue('removeActorsList');
    const addActorsList = actorForm.getFieldValue('addActorsList') || [];

    const data = {
      actor_type: selectedActorType,
      uuid: actorUUID,
    };

    if (addActorsList.length > 0) {
      data.add_actors_list = addActorsList;
    }

    if (removedActorList) {
      data.remove_actors_list = removedActorList;
    }

    if (Object.keys(data).length > 2) {
      requestUpdateActor(data);
    }
  };

  const createActor = (formData) => {
    let data = {
      actor_type: selectedActorType,
      uinfo: {
        ...formData,
      },
    };

    if (selectedActorType === 'service') {
      const uuid = get(formData, 'uuid');

      data = {
        ...data,
        uinfo: {
          ...data.uinfo,
          create_without_listing_group: get(formData, 'listingGroupFlag', false),
        },
        initial_key: get(formData, 'initial_key'),
      };

      if (uuid) {
        data = {
          ...data,
          uuid,
        };
      }
    }

    requestCreateActor(data).then((res) => {
      const { actor, backup_filename } = res || {};
      const {
        uinfo: {
          first_name,
          last_name,
        } = {},
      } = actor || {};

      switch (selectedActorType) {
        case 'classic_user':
        case 'user':
          checkNewAddedActorsAndUpdateUser(actor.uuid);
          // encryptUserToBackup(actor)
          break;
        case 'group':
        case 'service':
          checkNewAddedActorsAndUpdateUser(actor.uuid);
          break;
        default:
          break;
      }

      antNotification.success(getTranslate('auth.notifications.actor_created', 'actor created successful'));

      actorForm?.resetFields();

      if (backup_filename) {
        downLoadBackupFileRequest(backup_filename, `${first_name}_${last_name}`);
      }

      if (onSuccess) {
        onSuccess(actor);
      }
    });
  };

  const onSubmitForm = async () => {
    const changedInputs = actorForm.getFieldsValue(null, (meta) => meta.touched);

    const { birthday } = changedInputs || {};

    try {
      await actorForm.validateFields();

      const data = {
        ...changedInputs,
        ...(birthday ? { birthday: moment(birthday).format('YYYY-MM-DD') } : {}),
      };

      createActor({ ...data });
    } catch (error) {
      // console.log('error', error)
      antNotification.error(getTranslate('auth.notifications.invalid_data', 'invalid data entered'));
    }
  };

  useEffect(() => {
    actorForm.resetFields();
  }, [selectedActorType]);

  return (
    <IStickyBox offsetTop={16} offsetBottom={16}>
      <Spin spinning={creatingFetching}>
        <ActorFormWrapper
          {...formProps}
        >
          <Card className="card">
            <Row>
              <Col span={24}>
                <h4 className="header-primary">
                  {getTranslate('auth.headers.create_actor', 'creating new actor')}
                </h4>
              </Col>
            </Row>
            <hr className="mt-4 mb-0" />
            <InfoBlock
              iconPath={mdiCheckboxMultipleMarkedCircleOutline}
              title={getTranslate('auth.headers.choose_actor_type', 'choose actor type')}
            >
              <Radio.Group
                optionType="button"
                buttonStyle="solid"
                value={selectedActorType}
                onChange={handleChangeActorType}
              >
                {actorTypes.map((item) => {
                  const active = item.value === selectedActorType;

                  return (
                    <Radio.Button
                      key={`type_${item.value}`}
                      value={item.value}
                      className={`radio-item radio-item-${item.color} ${active ? 'active' : ''}`}
                    >
                      <div className="d-flex align-items-center">
                        <Icon
                          path={item.iconPath}
                          size={1.2}
                          className="slow mr-2"
                          rotate={active ? 720 : 0}
                        />
                        {item.label}
                      </div>
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            </InfoBlock>
            <hr className="my-4" />
            <Tabs
              activeKey={selectedActorType}
              tabBarStyle={{ display: 'none' }}
              destroyInactiveTabPane
              animated
            >
              <Tabs.TabPane key="classic_user">
                <UserInfoFormItems
                  {...formProps}
                  rules={rules.user}
                  hideElements={['oldPassword', 'passwordConfirmation']}
                />
              </Tabs.TabPane>
              <Tabs.TabPane key="user">
                <UserInfoFormItems
                  {...formProps}
                  rules={rules.user}
                  hideElements={['oldPassword', 'passwordConfirmation']}
                />
              </Tabs.TabPane>
              <Tabs.TabPane key="group">
                <GroupInfoFormItems {...formProps} rules={rules.group} />
              </Tabs.TabPane>
              <Tabs.TabPane key="service">
                <ServiceInfoFormItems {...formProps} rules={rules.service} />
              </Tabs.TabPane>
            </Tabs>
            <hr className="my-4" />
            <Row>
              <Col className="mr-auto d-flex">
                <Form.Item shouldUpdate>
                  {() => (
                    <Button
                      className="button-primary"
                      onClick={onSubmitForm}
                      disabled={!actorForm.isFieldsTouched()}
                    >
                      {getTranslate('auth.buttons.create_actor', 'create actor')}
                    </Button>
                  )}
                </Form.Item>
                <Button
                  className="button-secondary-outlined ml-2"
                  onClick={switchView}
                >
                  {getTranslate('auth.buttons.cancel', 'cancel')}
                </Button>
              </Col>
            </Row>
          </Card>
        </ActorFormWrapper>
      </Spin>
    </IStickyBox>
  );
}

export default ActorCreate;

ActorCreate.propTypes = {
  onSuccess: PropTypes.func,
  switchView: PropTypes.func.isRequired,
};
