import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Button, Card, Col, Form, Row } from 'antd';
import IStickyBox from 'react-sticky-box';
import { antNotification, capitalize } from '../../mainUtils';
import { useTranslation } from 'react-i18next';
import { ApiContext } from '../../api/ApiContextProvider';
import CertificateForm from './CertificateForm';


const CertificateCreate = ({
  switchView,
  onSuccessCreate
}) => {
  const { t } = useTranslation();
  const [certificateForm] = Form.useForm();

  const { requestCreateCertificate } = useContext(ApiContext);

  const onSubmitForm = async () => {
    try {
      const formData = await certificateForm.validateFields();

      requestCreateCertificate(formData).then(() => {
        antNotification.success(capitalize(t('auth.notifications.added_successfully', 'added successfully')));
        onSuccessCreate();
      });
    } catch (error) {
      antNotification.error(capitalize(t('auth.notifications.invalid_data', 'invalid data entered')));
    }
  };


  return (
    <IStickyBox offsetTop={16} offsetBottom={16}>
      <Card className={'card'}>
        <Row>
          <Col span={24}>
            <h4 className={'header-primary'}>
              {capitalize(t('auth.headers.create_certificate', 'creating new certificate'))}
            </h4>
          </Col>
        </Row>
        <hr className={'my-4'} />
        <Row>
          <Col span={24}>
            <CertificateForm
              form={certificateForm}
            />
          </Col>
        </Row>
        <hr className={'my-4'} />
        <Row>
          <Col className={'mr-auto'}>
            <Button
              className={'button-primary'}
              onClick={onSubmitForm}
            >
              {capitalize(t('auth.buttons.create_certificate', 'create certificate'))}
            </Button>
            <Button
              className={'button-secondary-outlined ml-2'}
              onClick={() => switchView()}
            >
              {capitalize(t('auth.buttons.cancel', 'cancel'))}
            </Button>
          </Col>
        </Row>
      </Card>
    </IStickyBox>
  );
};

export default CertificateCreate;

CertificateCreate.propTypes = {
  onSuccessCreate: PropTypes.func.isRequired,
  switchView: PropTypes.func.isRequired
};
