import PropTypes from 'prop-types';
import React, {
  useContext, useEffect, useState, useId,
} from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AntTableWithPagination from '../../components/AntTableWithPagination';

import { isActorsFetching } from '../selectors';
import { ApiContext } from '../../api/ApiContextProvider';

function ListOfActorsBasic({
  actorUUID,
  columns,
  filterByTags,
  getActorTypes = [],
  getListOfActorsAgain,
  ignoreSearchCase,
  newCurrentPage,
  orderRules,
  searchValue,
  selectActor,
  switchView,
}) {
  const { t } = useTranslation();

  const uniqID = useId();

  const {
    requestGetAllActors,
  } = useContext(ApiContext);

  const fetchingActors = useSelector(isActorsFetching);

  const [actors, setActors] = useState([]);
  const [actorsCount, changeActorsCount] = useState(0);
  const [paginationOptions, changePaginationOptions] = useState();

  const changePagination = (data) => {
    changePaginationOptions(data);
  };

  const rowSelection = {
    columnWidth: 40,
    selectedRowKeys: [actorUUID],
  };

  const onRow = (row) => ({
    onClick: (e) => {
      e.stopPropagation();
      if (actorUUID === row.uuid) {
        switchView();
      } else if (selectActor) {
        selectActor('info', row.type, row.uuid);
      }
    },
    onDoubleClick: (e) => {
      e.stopPropagation();

      if (selectActor) {
        selectActor('permissions', row.type, row.uuid);
      }
    },
  });

  const getActorsAndCount = async (params) => {
    const types = [
      `GET_ALL_ACTORS_REQUEST_${uniqID}`,
      `GET_ALL_ACTORS_SUCCESS_${uniqID}`,
      `GET_ALL_ACTORS_FAILURE_${uniqID}`,
    ];

    const data = {
      actor_type: getActorTypes,
      ...params,
    };

    if (searchValue) {
      data.search_data = {
        value: searchValue,
        ignore_case: ignoreSearchCase,
        fields:
            {
              base: ['uuid'],
              uinfo: ['first_name', 'last_name', 'group_name', 'service_name'],
            },
      };
    }

    const allActors = await requestGetAllActors(data, types);

    changeActorsCount(_.get(allActors, 'total'));
    setActors(allActors?.actors || []);
  };

  const initFunc = () => {
    if (paginationOptions) {
      const { pageLimit, offset } = paginationOptions;

      const params = {
        limit: pageLimit,
        offset,
        ...orderRules,
      };

      getActorsAndCount(params);
    }
  };

  useEffect(() => {
    initFunc();
  }, [
    paginationOptions,
    JSON.stringify(orderRules),
    JSON.stringify(filterByTags),
    JSON.stringify(getListOfActorsAgain),
  ]);

  return (
    <AntTableWithPagination
      columns={columns}
      data={actors}
      loading={fetchingActors}
      onRow={onRow}
      rowSelection={rowSelection}
      total={actorsCount}
      newCurrentPage={newCurrentPage}
      getPaginationOptions={changePagination}
    />
  );
}

export default ListOfActorsBasic;

ListOfActorsBasic.propTypes = {
  actorUUID: PropTypes.string,
  columns: PropTypes.any,
  filterByTags: PropTypes.any,
  getActorTypes: PropTypes.array,
  getListOfActorsAgain: PropTypes.any,
  ignoreSearchCase: PropTypes.bool,
  newCurrentPage: PropTypes.any,
  orderRules: PropTypes.any,
  searchValue: PropTypes.any,
  selectActor: PropTypes.any,
  switchView: PropTypes.any
}
