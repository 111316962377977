import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import IStickyBox from 'react-sticky-box';
import { Button, Card, Col, Form, Row } from 'antd';
import { antNotification, capitalize } from '../../mainUtils';
import { useTranslation } from 'react-i18next';
import InterfaceForm from './InterfaceForm';
import { ApiContext } from '../../api/ApiContextProvider';


const InterfaceCreate = ({
  resetView,
  onSuccessCreate
}) => {
  const { t } = useTranslation();
  const [interfaceForm] = Form.useForm();
  const { requestCreateInterface } = useContext(ApiContext);

  const initInterfaceData = {
    icon: 'mdi-cube',
    color: 'DARK'
  };
  const [interfaceData, setInterfaceData] = useState(initInterfaceData);

  const onSubmitForm = async () => {
    const {
      icon,
      color
    } = interfaceData;

    try {
      const formData = await interfaceForm.validateFields();

      const data = {
        ...formData,
        'service_icon': icon,
        'icon_color': color,
        'params': JSON.parse(formData.params)
      };

      requestCreateInterface(data).then((response) => {
        antNotification.success(
          capitalize(t('auth.notifications.interface_created', 'interface created successful')));

        interfaceForm.resetFields();
        setInterfaceData(interfaceData);

        resetView();

        if (onSuccessCreate) {
          onSuccessCreate(response);
        }
      });
    } catch (error) {
      antNotification.error(capitalize(
        t(
          'auth.notifications.invalid_data',
          'invalid data entered'
        )));
    }
  };

  const initFunc = () => {
    interfaceForm.setFieldsValue({
      'params': 'null'
    });
  };

  useEffect(() => {
    initFunc();
  }, []);


  return (
    <IStickyBox offsetTop={16} offsetBottom={16}>
      <Card className={'card'}>
        <Row>
          <Col span={24}>
            <h4 className={'header-primary'}>
              {capitalize(t('auth.headers.create_interface', 'creating new interface'))}
            </h4>
          </Col>
        </Row>
        <hr className={'mt-4 mb-0'} />
        <InterfaceForm
          form={interfaceForm}
          interfaceData={interfaceData}
          setInterfaceData={setInterfaceData}
          editMode
        />
        <hr className={'my-4'} />
        <Row>
          <Col className={'mr-auto'}>
            <Button
              className={'button-primary'}
              onClick={onSubmitForm}
            >
              {capitalize(t('auth.buttons.create_interface', 'create interface'))}
            </Button>
            <Button
              className={'button-secondary-outlined ml-2'}
              onClick={resetView}
            >
              {capitalize(t('auth.buttons.cancel', 'cancel'))}
            </Button>
          </Col>
        </Row>
      </Card>
    </IStickyBox>
  );
};

export default InterfaceCreate;

InterfaceCreate.propTypes = {
  editMode: PropTypes.bool,
  onSuccessCreate: PropTypes.func,
  resetView: PropTypes.func.isRequired
};
