import PropTypes from 'prop-types';
import { Form } from 'antd';

function ActorFormWrapper(props) {
  const {
    actorForm,
    validateMessages,
    onFieldsChange,
    initialValues = [],
    children,
  } = props;

  return (
    <Form
      layout="vertical"
      autoComplete="off"
      form={actorForm}
      validateMessages={validateMessages}
      initialValues={initialValues}
      onFieldsChange={onFieldsChange}
    >
      {children}
    </Form>
  );
}

export default ActorFormWrapper;

ActorFormWrapper.propTypes = {
  actorForm: PropTypes.object.isRequired,
  children: PropTypes.element,
  initialValues: PropTypes.array,
  validateMessages: PropTypes.object,
};
