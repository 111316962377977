import React from 'react';
import { Col, Descriptions, Row } from 'antd';
import {get} from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { capitalize } from '../mainUtils';
import { getAuthBiomeInfo, getAuthServiceInfo } from '../auth/selectors';

const {Item} = Descriptions;

const BiomeInfoPage = () => {
  const biome = useSelector(getAuthBiomeInfo);
  const auth = useSelector(getAuthServiceInfo);

  const { t } = useTranslation();

  const descriptionPlaceholder = (
    <span className={'text-secondary'}>
      {capitalize(t('auth.placeholders.no_description', 'no description'))}
    </span>
  );

  return (
      <Row>
        <Col span={14}>
          {/*<Row>*/}
          {/*  <Col>*/}
          {/*    <h4 className={'header-primary mb-0 mr-3'}>*/}
          {/*      {capitalize(t('auth.headers.biome', 'biome'))}*/}
          {/*    </h4>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          <hr className={'my-4'} />
          <Row>
            <Col span={24}>
              <Descriptions
                  bordered
                  column={1}
              >
                <Item
                    label={capitalize(t('auth.headers.name', 'name'))}
                    labelStyle={{ width: '33%' }}
                >
                    <span className={'text-primary'}>
                      {get(biome, 'name') || descriptionPlaceholder}
                    </span>
                </Item>
                <Item
                    label={'UUID'}
                    labelStyle={{ width: '33%' }}
                >
                    <span className={'text-primary'}>
                      {get(biome, 'uuid') || descriptionPlaceholder}
                    </span>
                </Item>
                <Item
                    label={capitalize(t('auth.headers.domain', 'domain'))}
                    labelStyle={{ width: '33%' }}
                >
                    <span className={'text-primary'}>
                      {get(biome, 'domain') || descriptionPlaceholder}
                    </span>
                </Item>
                <Item
                    label={capitalize(t('auth.headers.auth_biom_public_key', 'auth biome public key'))}
                    labelStyle={{ width: '33%' }}
                >
                    <span className={'text-primary'}>
                      {get(auth, 'public_key') || descriptionPlaceholder}
                    </span>
                </Item>
              </Descriptions>
            </Col>
          </Row>
        </Col>
      </Row>
  );
};

export default BiomeInfoPage;
