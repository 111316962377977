import {
  mdiClose,
  mdiContentSaveOutline
} from '@mdi/js';
import Icon from '@mdi/react';
import {
  Button,
  Card,
  Col,
  Input,
  Result,
  Row,
  Space,
  Spin
} from 'antd';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';

import PermissionsButtonJSON from './PermissionsButtonJSON';
import PermissionsList from './PermissionsList';
import AdminServicePerm from "./AdminServicePerm";

import { isInfoFetching } from '../../actors/selectors';
import { capitalize } from '../../mainUtils';
import {
  getActorPermissionsMap,
  getGroupPermissionsMap
} from '../selectors';


const PermissionsManagement = ({
  actorType,
  attributes,
  changeServiceAdmin,
  checkUpdatedPermissions,
  dataTable,
  handleSelectPermission,
  hasUnsavedData,
  hideElements = [],
  onUpdatePermissions,
  permissionsFilter,
  resetPermissions,
  selectedPermission,
  selectedPermissionTableType,
  setDataTable,
  setDeletedPermissions,
  setPermissionTableType,
  setUpdatedPermissions,
  userWithSpecialPermissions
}) => {
  const { t } = useTranslation();

  const isActorFetching = useSelector(isInfoFetching);

  const actorPermissionsMap = useSelector(getActorPermissionsMap);
  const groupPermissionsMap = useSelector(getGroupPermissionsMap);
  const [searchValue, setSearchValue] = useState('');

  const getPermUUID = (perm) => _.get(perm, 'permaction_uuid');

  const getDataTable = (type) => {
    let data = dataTable[type]?.size || 0 > 0 ? [...dataTable[type].values()] : [];

    if (searchValue) {
      data =
        data.filter(({
          title,
          permaction_uuid: uuid
        }) =>
          `${title}${uuid}`.match(new RegExp(searchValue, 'i')));
    }

    if (attributes.length > 0) {
      data =
        data.filter(({ unions }) => _.difference(
          unions,
          attributes,
        ).length !== unions.length);
    }

    return data;
  };

  const getPermissionsForJSON = () => {
    const permissions = actorType === 'group'
      ? [...dataTable.group.values()]
      : [...dataTable.actor.values()];
    return permissions.map((item) => (
      { ..._.pick(item, ['permaction_uuid', 'value', 'params']) }
    ));
  };

  const defaultPermissionUUIDs = new Set(
    [...dataTable.default.values()].map(({ permaction_uuid: uuid }) => uuid)
  );

  const onDeletePermission = (uuid) => {
    if (getPermUUID(selectedPermission) === uuid) {
      switch (actorType) {
        case 'group':
          setPermissionTableType('default');
          break;
        case 'user':
        case 'service':
        case 'classic_user':
          if (groupPermissionsMap.has(uuid)) {
            setPermissionTableType('group');
          } else {
            setPermissionTableType('default');
          }
          break;
        default:
          break;
      }
    }

    setDataTable((prev) => {
      if (actorType === 'group') {
        prev.group.delete(uuid);
      } else {
        prev.actor.delete(uuid);
      }

      return {
        ...prev
      };
    });

    const currentMap = actorType === 'group'
      ? groupPermissionsMap
      : actorPermissionsMap;

    if (currentMap.has(uuid)) {
      setDeletedPermissions((prev) => [...prev, uuid]);
    }

    setUpdatedPermissions((prev) => prev.filter((item) => item !== uuid));
  };

  const onOverridePermission = (uuid, type) => {
    const data = dataTable[type].get(uuid);

    if (getPermUUID(selectedPermission) === uuid) {
      if (actorType === 'group') {
        setPermissionTableType('group');
      } else {
        setPermissionTableType('actor');
      }
    }

    setDataTable((prev) => {
      if (actorType === 'group') {
        prev.group.set(uuid, data);
      } else {
        prev.actor.set(uuid, data);
      }

      return {
        ...prev
      };
    });

    setDeletedPermissions((prev) => prev.filter((item) => item !== uuid));
    checkUpdatedPermissions(uuid, data);
  };

  const onSwitchValue = (uuid, data, value) => {
    setDataTable((prev) => {
      if (actorType === 'group') {
        prev.group.set(
          uuid,
          {
            ...data,
            value: +value
          },
        );
      } else {
        prev.actor.set(
          uuid,
          {
            ...data,
            value: +value
          },
        );
      }

      return {
        ...prev
      };
    });

    checkUpdatedPermissions(
      uuid,
      {
        ...data,
        value: +value
      },
    );
  };

  const onSetPermissionsJSON = (permissions) => {
    const permissionsMap = new Map([
      ...permissions.map((item) => [getPermUUID(item), item])
    ]);

    const currentDataTable = actorType === 'group'
      ? dataTable.group
      : dataTable.actor;

    [...currentDataTable.values()].forEach((permission) => {
      const uuid = getPermUUID(permission);

      if (!permissionsMap.has(uuid)) {
        onDeletePermission(uuid);
      }
    });

    permissions.forEach((permission) => {
      const uuid = getPermUUID(permission);
      const defaultData = dataTable.default.get(uuid);
      const data = {
        ...defaultData,
        ...permission
      };

      if (actorType === 'group') {
        dataTable.group.set(uuid, data);
      } else {
        dataTable.actor.set(uuid, data);
      }

      checkUpdatedPermissions(uuid, data);
    });
  };

  const permissionsTableProps = {
    onSelectPermission: handleSelectPermission,
    selectedPermissionUUID: getPermUUID(selectedPermission),
    selectedPermissionTableType,
    onSwitchValue,
    onOverridePermission,
    onDeletePermission,
    dataTable,
    getDataTable,
    actorType
  };

  return (
    <>
      <Prompt
        message={capitalize(t('auth.notifications.leave_page'))}
        when={hasUnsavedData}
      />
      <Spin spinning={isActorFetching}>
        {userWithSpecialPermissions ? (
          <Card className={'card'}>
            <Result
              title={capitalize(t(`auth.headers.perms_is_${userWithSpecialPermissions}`))}
              subTitle={capitalize(t(`auth.notifications.perms_is_${userWithSpecialPermissions}`))}
              status={userWithSpecialPermissions === 'ban' ? 'warning' : 'info'}
            />
          </Card>
        ) : (
          <Card className={'card'}>
            <Row gutter={[10, 10]}>
              <Col className={'d-flex align-items-center'}>
                <h4 className={'header-primary'}>
                  {capitalize(t(
                    'auth.headers.permissions',
                    'permissions',
                  ))}:
                </h4>
              </Col>
              <Col span={8}>
                <Input.Search
                  allowClear
                  onSearch={setSearchValue}
                  className={'w-100'}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder={capitalize(t(
                    'auth.placeholders.search_permissions',
                    'enter permission name',
                  ))}
                />
              </Col>
              <Col
                flex={'auto'}
                className={'d-flex justify-content-end'}
              >
                <Space
                  size={[5, 1]}
                  wrap
                  className={'justify-content-end'}
                >
                  <PermissionsButtonJSON
                    actorPermissions={getPermissionsForJSON()}
                    onSetJSON={onSetPermissionsJSON}
                    defaultPermissionUUIDs={defaultPermissionUUIDs}
                    // disabled
                  />
                  <Button
                    className={'button-secondary-outlined'}
                    size={'small'}
                    onClick={resetPermissions}
                    disabled={!hasUnsavedData}
                  >
                    <Icon
                      path={mdiClose}
                      size={1}
                      className={'mr-1'}
                    />
                    {capitalize(t('auth.buttons.cancel', 'cancel'))}
                  </Button>
                  <Button
                    className={'button-primary'}
                    size={'small'}
                    onClick={onUpdatePermissions}
                    disabled={!hasUnsavedData}
                  >
                    <Icon
                      path={mdiContentSaveOutline}
                      size={1}
                      className={'mr-1'}
                    />
                    {capitalize(t('auth.buttons.save', 'save'))}
                  </Button>
                </Space>
              </Col>
            </Row>
            <hr className={'my-4'} />
            <Row>
              <Col>
                {permissionsFilter}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <hr className={'my-4'} />
                <Row gutter={[16, 16]}>
                  {!hideElements.includes('actor') && actorType !== 'group' && (
                    <PermissionsList
                      permissionsType={'actor'}
                      {...permissionsTableProps}
                    />
                  )}

                  {!hideElements.includes('group') &&
                    <PermissionsList
                        permissionsType={'group'}
                        {...permissionsTableProps}
                    />
                  }
                  {!hideElements.includes('default') &&
                    <PermissionsList
                      permissionsType={'default'}
                      {...permissionsTableProps}
                    />
                  }

                  {!hideElements.includes('admin') &&
                    <AdminServicePerm
                        changeServiceAdmin={changeServiceAdmin}
                    />
                  }
                </Row>
              </Col>
            </Row>
          </Card>
        )}
      </Spin>
    </>
  );
};

export default PermissionsManagement;

PermissionsManagement.propTypes = {
  actorType: PropTypes.string,
  attributes: PropTypes.array,
  checkUpdatedPermissions: PropTypes.func.isRequired,
  dataTable: PropTypes.object.isRequired,
  handleSelectPermission: PropTypes.func.isRequired,
  hasUnsavedData: PropTypes.bool,
  onUpdatePermissions: PropTypes.func.isRequired,
  permissionsFilter: PropTypes.element.isRequired,
  resetPermissions: PropTypes.func.isRequired,
  selectedPermission: PropTypes.object,
  selectedPermissionTableType: PropTypes.string,
  setDataTable: PropTypes.func.isRequired,
  setDeletedPermissions: PropTypes.func.isRequired,
  setPermissionTableType: PropTypes.func.isRequired,
  setUpdatedPermissions: PropTypes.func.isRequired,
  userWithSpecialPermissions: PropTypes.string
};
