import React, {useState} from 'react';
import { Switch} from "antd";
import {useTranslation} from "react-i18next";

import { capitalize} from "../../mainUtils";


const GenerateKeyPairForActorBtn = ({
    switchValueCallback
}) => {
    const {t} = useTranslation();

    const [switchValue, setSwitchValue] = useState(false);

    const changeSwitch = () => {
        setSwitchValue(!switchValue)

        if(switchValueCallback) {
            switchValueCallback(!switchValue)
        }
    }

    return(
        <Switch
            className={'switch-primary'}
            checkedChildren={capitalize(t('auth.buttons.yes', 'yes'))}
            unCheckedChildren={capitalize(t('auth.buttons.no', 'no'))}
            checked={switchValue}
            onClick={changeSwitch}
        />
    )
}

export default GenerateKeyPairForActorBtn;
