import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Input, Popconfirm, Row, Tag } from 'antd';
import { antNotification, capitalize } from '../../mainUtils';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiCheck, mdiClose, mdiLinkVariant, mdiPlusCircle, mdiTrashCanOutline } from '@mdi/js';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import AntTable from '../../components/AntTable';
import InterfaceIcon from './interfaceIcon';
import DeleteButton from '../../components/DeleteButton';
import InterfaceCreate from './InterfaceCreate';
import InterfaceInfo from './InterfaceInfo';
import { Link } from 'react-router-dom';
import { getInterfacesList, isUIFetching } from '../selectors';
import { ApiContext } from '../../api/ApiContextProvider';
import PageWrapper from '../../components/PageWrapper';


const InterfacesPage = () => {
  const { t } = useTranslation();
  const {
    requestGetAllInterfaces,
    requestGetOpenInterfaces,
    requestUpdateInterface
  } = useContext(ApiContext);

  const allInterfaces = useSelector(getInterfacesList);
  const fetchingInterfaces = useSelector(isUIFetching);

  const [searchValue, setSearchValue] = useState('');
  const [interfaceID, setInterfaceID] = useState(null);
  const [currentView, setCurrentView] = useState('');
  const [tableData, setTableData] = useState([]);

  const isCreateView = currentView === 'create';

  const switchView = (view, id) => {
    setCurrentView(view || '');
    setInterfaceID(id || null);
  };

  const getAllInterfaces = () => {
    return new Promise((resolve) => {
      requestGetAllInterfaces().then((response) => {
        requestGetOpenInterfaces().then();
        resolve(_.get(response, 'public_interface', []));
      });
    });
  };

  const onSearchInterface = (value) => {
    if (value) {
      setTableData(
        allInterfaces
          .filter((item) => item.service_name.match(new RegExp(value, 'i')))
      );
    } else {
      setTableData(allInterfaces);
    }
  };

  const resetView = () => switchView();

  const updateDisplay = (row) => {
    const data = {
      ...row,
      'display_service': !row.display_service
    };

    requestUpdateInterface(data).then(() => {
      antNotification.success(
        capitalize(t('auth.notifications.updated_successfully', 'updated successfully')));
      getAllInterfaces().then();
    });
  };

  const afterDelete = () => {
    resetView();
    getAllInterfaces().then();
  };

  const rowSelection = {
    selectedRowKeys: interfaceID ? [_.toString(interfaceID)] : []
  };

  const onRow = (row) => {
    return {
      onClick: (e) => {
        e.stopPropagation();
        switchView('info', row.id);
      }
    };
  };

  const formatIcon = (cell, row) => (
    <InterfaceIcon
      iconName={_.get(row, 'service_icon')}
      color={_.get(row, 'icon_color')}
      size={1.6}
    />
  );

  // const formatName = (cell, row) => (
  //   <Tooltip
  //     title={`interface_${row.id}`}
  //   >
  //     {cell}
  //   </Tooltip>
  // );

  const formatDomain = (cell) => (
    <Link
      to={{ pathname: cell }}
      target={'_blank'}
    >
      <Button
        className={'button-primary-link'}
        size={'small'}
        onClick={(e) => e.stopPropagation()}
      >
        <Icon path={mdiLinkVariant} size={1.2} className={'mr-2'} />
        <span
          className={'d-inline-block text-truncate'}
          style={{ maxWidth: 300 }}
        >
          {cell}
        </span>
      </Button>
    </Link>
  );

  const formatDisplay = (cell, row) => (
    <Popconfirm
      title={capitalize(t('auth.headers.change_display', 'change display'))}
      onConfirm={(e) => {
        e.stopPropagation();
        updateDisplay(row);
      }}
      onCancel={(e) => e.stopPropagation()}
      cancelText={capitalize(t('auth.buttons.cancel', 'cancel'))}
      okText={capitalize(t('auth.buttons.submit', 'submit'))}
      okButtonProps={{ className: 'button-primary' }}
      cancelButtonProps={{ className: 'button-secondary-outlined' }}
    >
      <Tag
        className={`tag-icon tag-${cell ? 'green' : 'red'}`}
        onClick={(e) => e.stopPropagation()}
      >
        <Icon path={cell ? mdiCheck : mdiClose} size={1.2} />
      </Tag>
    </Popconfirm>
  );

  const formatDelete = (id) => (
    <DeleteButton
      targetType={'public_interface'}
      onSuccess={afterDelete}
      data={{ id }}
    >
      <Button
        className={'button-danger-outlined'}
        size={'small'}
      >
        <Icon path={mdiTrashCanOutline} size={1} />
      </Button>
    </DeleteButton>
  );

  const columns = [
    {
      dataIndex: 'service_icon',
      key: 'service_icon',
      className: 'p-2 pl-3',
      render: formatIcon
    },
    {
      dataIndex: 'service_name',
      key: 'service_name',
      className: 'p-2',
      title: capitalize(t('auth.headers.title', 'title')),
      sorter: (a, b) =>
        _.get(a, 'service_name', '')
          .localeCompare(_.get(b, 'service_name', ''))
    },
    {
      dataIndex: 'service_domain',
      key: 'service_domain',
      className: 'p-2',
      title: capitalize(t('auth.headers.domain', 'domain')),
      render: formatDomain
    },
    {
      dataIndex: 'display_service',
      key: 'display_service',
      className: 'p-2',
      title: capitalize(t('auth.headers.display', 'display')),
      sorter: (a, b) => +_.get(a, 'display_service') - +_.get(
        b,
        'display_service'
      ),
      render: formatDisplay
    },
    {
      dataIndex: 'id',
      key: 'id',
      className: 'p-2  pl-3',
      title: '',
      render: formatDelete
    }
  ];

  const afterCreate = (response) => {
    const id = _.get(response, 'public_interface.id');
    switchView('info', id);
    getAllInterfaces().then();
  };

  const initFunc = () => {
    getAllInterfaces().then();
  };

  useEffect(() => {
    onSearchInterface(searchValue);
  }, [searchValue, allInterfaces]);

  useEffect(() => {
    initFunc();
  }, []);


  return (
    <PageWrapper
      title={capitalize(t('auth.pages.public_interfaces', 'public interfaces'))}
    >
      <Row gutter={[24, 16]}>
        <Col span={14}>
          <Card className={'card border-blue'}>
            <Row gutter={[10, 10]}>
              <Col
                className={'d-flex align-items-center'}
                order={0}
              >
                <h4 className={'header-primary mb-0 mr-3'}>
                  {capitalize(t(
                    'auth.headers.interfaces',
                    'interfaces'
                  ))}
                </h4>
              </Col>
              <Col
                className={'d-flex align-items-center'}
                span={8}
              >
                <Input.Search
                  allowClear
                  onSearch={setSearchValue}
                  className={'w-100'}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder={capitalize(t(
                    'auth.placeholders.search_interface',
                    'enter interface name'
                  ))}
                />
              </Col>
              <Col
                className={'d-flex ml-auto flex-wrap justify-content-end'}
              >
                {interfaceID && (
                  <Button
                    className={'flex-fill button-warning-outlined mr-2'}
                    onClick={resetView}
                  >
                    {capitalize(t('auth.buttons.reset', 'reset'))}
                  </Button>
                )}
                <Button
                  className={isCreateView ?
                    'button-warning-outlined' :
                    'button-primary-outlined'}
                  onClick={() => {
                    if (isCreateView) {
                      resetView();
                    } else {
                      switchView('create');
                    }
                  }}
                >
                  <Icon
                    path={mdiPlusCircle}
                    size={1.5}
                    className={'slow mr-2'}
                    rotate={isCreateView ? 765 : 0}
                  />
                  {isCreateView ? (
                    capitalize(t('auth.buttons.cancel', 'cancel'))
                  ) : (
                    capitalize(t('auth.buttons.create_interface', 'create interface'))
                  )}
                </Button>
              </Col>
            </Row>
            <hr className={'my-4'} />
            <Row>
              <Col span={24}>
                <AntTable
                  rowKey={'id'}
                  data={tableData}
                  columns={columns}
                  loading={fetchingInterfaces}
                  onRow={onRow}
                  rowSelection={rowSelection}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        {currentView === 'create' && (
          <Col span={10}>
            <InterfaceCreate
              resetView={resetView}
              onSuccessCreate={afterCreate}
              editMode
            />
          </Col>
        )}
        {currentView === 'info' && interfaceID && (
          <Col span={10}>
            <InterfaceInfo
              interfaceID={interfaceID}
              onSuccessUpdate={getAllInterfaces}
              onSuccessDelete={afterDelete}
            />
          </Col>
        )}
      </Row>
    </PageWrapper>
  );
};

export default InterfacesPage;
