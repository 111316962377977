import PropTypes from 'prop-types';
import React, { useReducer } from 'react';
import { Modal, Tabs } from 'antd';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import ActorLogsTableAndFilters from './ActorLogsTableAndFilters';
import CreateActorOnService from '../CreateActorOnService';

import { getActorsInfo } from '../../selectors';
import { getNameForActor } from '../../../54origins/utils54origins';
import i18n from '../../../i18n';
import { capitalize } from '../../../mainUtils';
import AntButtonWithMDI from '../../../components/AntButtonWithMDI';

const translates = {
  tab1: ['actor on services', 'эктор на сервисе'],
  tab2: ['unsynchronized changes', 'изменения которые не были синхронизированы'],
  syncDashboard: ['sync dashboard', 'дашборд синх.'],
  syncDashboardFor: ['sync dashboard for', 'дашборд синхронизации для'],
};

const translateFromLocalDict = (key, isCapitalize = true) => {
  const translate = translates[key][i18n.language === 'en' ? 0 : 1];
  return isCapitalize ? capitalize(translate) : translate;
};

function SyncDashboardBtnModal({
  disabledBtn,
}) {
  const { t } = useTranslation();
  const actorData = useSelector(getActorsInfo);

  const [modalVisible, setModalVisible] = useReducer((state) => !state, false);

  const tabs = [
    {
      label: translateFromLocalDict('tab1'),
      key: '1',
      children: <CreateActorOnService />,
    },
    {
      label: translateFromLocalDict('tab2'),
      key: '2',
      children: <ActorLogsTableAndFilters />,
    },
  ];

  return (
    <>
      <AntButtonWithMDI
        className="button-primary-outlined"
        size="small"
        onClick={setModalVisible}
        disabled={disabledBtn}
        label={translateFromLocalDict('syncDashboard')}
      />
      <Modal
        title={`${translateFromLocalDict('syncDashboardFor')} ${getNameForActor(actorData)}`}
        open={modalVisible}
        width="70%"
        destroyOnClose
        onCancel={setModalVisible}
        footer={(
          <AntButtonWithMDI
            type="primary"
            onClick={() => setModalVisible()}
            label={t('54origins.verb.cancel', 'cancel')}
          />
          )}
      >
        <Tabs
          // onChange={() => {}}
          type="card"
          items={tabs}
          destroyInactiveTabPane
        />
      </Modal>
    </>
  );
}

export default SyncDashboardBtnModal;

SyncDashboardBtnModal.propTypes = {
  disabledBtn: PropTypes.bool,
};
