import React from 'react';
import { AuthHelper } from '../auth/utils';
import { Link, useHistory } from 'react-router-dom';
import {
  mdiAccountCircleOutline,
  mdiAccountCogOutline,
  mdiCertificateOutline,
  mdiCloudSyncOutline,
  mdiHexagonMultipleOutline,
  mdiHomeOutline,
  mdiInformationOutline, mdiMonitorDashboard,
  mdiMonitorScreenshot,
  mdiShieldCheckOutline,
  mdiTextBoxSearchOutline
} from '@mdi/js';
import Icon from '@mdi/react';
import { capitalize } from '../mainUtils';
import { useTranslation } from 'react-i18next';
import VersionLabel from './VersionLabel';
import { useSelector } from 'react-redux';
import { getMeIsAdmin, getMeIsRoot } from '../profile/selectors';


const SidebarComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const meIsAdmin = useSelector(getMeIsAdmin);
  const meIsRoot = useSelector(getMeIsRoot);

  const menuItems = [
    {
      label: capitalize(t('auth.pages.profile', 'profile')),
      linkTo: '/admin/profile',
      iconPath: mdiAccountCircleOutline
    },
    {
      label: capitalize(t('auth.pages.start_page', 'start page')),
      linkTo: '/admin/start-page',
      iconPath: mdiHomeOutline,
      onlyAdmin: true
    },
    // {
    //   label: capitalize(t('auth.pages.biome_info', 'biome info')),
    //   linkTo: '/admin/biome-info',
    //   iconPath: mdiInformationOutline,
    //   onlyAdmin: true
    // },
    {
      label: capitalize(t('auth.pages.actors', 'actors')),
      linkTo: '/admin/actors',
      iconPath: mdiAccountCogOutline,
      onlyAdmin: true
    },
    {
      label: capitalize(t('auth.pages.public_interfaces', 'public interfaces')),
      linkTo: '/admin/public-interfaces',
      iconPath: mdiMonitorScreenshot,
      onlyAdmin: true
    },
    {
      label: capitalize(t('auth.pages.groups_priority', 'groups priority')),
      linkTo: '/admin/groups-priority',
      iconPath: mdiHexagonMultipleOutline,
      onlyAdmin: true
    },
    {
      label: capitalize(t('auth.pages.logs', 'logs')),
      linkTo: '/admin/logs',
      iconPath: mdiTextBoxSearchOutline,
      onlyAdmin: true
    },
    // {
    //   label: capitalize(t('auth.pages.sync_services', 'sync services')),
    //   linkTo: '/admin/sync-services',
    //   iconPath: mdiCloudSyncOutline,
    //   onlyAdmin: true
    // },
    // {
    //   label: capitalize(t('auth.pages.certificates', 'SSL certificates')),
    //   linkTo: '/admin/certificates',
    //   iconPath: mdiCertificateOutline,
    //   onlyAdmin: true
    // },
    // {
    //   label: capitalize(t('auth.pages.security_policy', 'security policy')),
    //   linkTo: '/admin/security-policy',
    //   iconPath: mdiShieldCheckOutline,
    //   onlyAdmin: true
    // },
    {
      label: capitalize(t('auth.pages.biome_dashboard', 'biome dashboard')),
      linkTo: '/admin/biome-dashboard',
      iconPath: mdiMonitorDashboard,
      onlyAdmin: true
    },
  ];


  return (
    <aside id={'main-sidebar'} className={'dt-sidebar'}>
      <div className={'dt-sidebar__container'}>
        {AuthHelper.isAuthorised() && (
          <div className={'d-flex flex-column justify-content-between mt-4'}>
            <ul className={'menu-items'}>
              {menuItems.map((item, id) => {
                const isHiddenTab = item.onlyAdmin && (!meIsAdmin && !meIsRoot);

                if (isHiddenTab) {
                  return null;
                }

                return (
                  <li key={`${id}_${item.linkTo}`} className={'dt-side-nav__item'}>
                    <VersionLabel version={item.version}>
                      <Link
                        to={item.linkTo}
                        className={`dt-side-nav__link dt-side-nav__link${history.location.pathname.includes(item.linkTo.split(
                          '?')[0]) ?
                          '--active' :
                          ''}`}
                      >
                        <div className={'mr-3'}>
                          <Icon path={item.iconPath} size={1.5} />
                        </div>
                        <p className={'dt-side-nav__text'}>
                          {item.label}
                        </p>
                      </Link>
                    </VersionLabel>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </aside>
  );
};

export default SidebarComponent;
