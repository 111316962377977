import React from 'react';
import { Button, Card, Col, Row, Timeline } from 'antd';
import Icon from '@mdi/react';
import { mdiAccountGroupOutline, mdiAccountMultiplePlusOutline, mdiContentCopy } from '@mdi/js';
import { capitalize, textToClipboard } from '../../mainUtils';
import { useTranslation } from 'react-i18next';
import PageWrapper from '../../components/PageWrapper';


const StartPage = () => {
  const { t } = useTranslation();

  const roles = [
    {
      label: 'Default',
      description: t('start_page.content.roles.default'),
      color: 'blue'
    },
    {
      label: 'Admin',
      description: t('start_page.content.roles.admin'),
      color: 'orange'
    },
    {
      label: 'Root',
      description: t('start_page.content.roles.root'),
      color: 'purple'
    },
    {
      label: 'Ban',
      description: t('start_page.content.roles.ban'),
      color: 'red'
    }
  ];

  const roleItem = role => {
    return (
      <li key={role.label} className={'text-dark-blue'}>
        <span className={`badge-${role.color}`}>
          {role.label.toUpperCase()}
        </span>{' '}
        - {role.description}
      </li>
    );
  };

  const wmsLink = `${window.location.protocol}//${window.location.host.replace(
    'auth',
    'wms'
  )}`;

  const copyButton = () => {
    return (
      <Button
        className={'button-primary-outlined mx-3'}
        size={'small'}
        onClick={() => textToClipboard(wmsLink)}
      >
        <Icon className={'mr-1'} path={mdiContentCopy} size={1} />
        <span>{wmsLink}</span>
      </Button>
    );
  };

  const stepDot = iconPath => {
    return (
      <div className={'dot-step'}>
        <Icon path={iconPath} size={1.5} />
      </div>
    );
  };

  return (
    <PageWrapper
      title={capitalize(t('auth.pages.start_page', 'start page'))}
    >
      <Row>
        <Col span={24} xxl={16} className={'mb-5'}>
          <Card className={'card'}>
            <h2 className={'header-primary'}>
              {t('start_page.headers.welcome', 'Welcome to Auth54!')}
            </h2>
            <hr />
            <h3 className={'header-primary'}>
              {t('start_page.content.welcome')}
            </h3>
            <p className={'p-primary'}>
              <span className={'accent'}>Auth54</span> - {t('start_page.content.auth_0')}
              <span className={'accent'}>{' Root '}</span>
              {t('start_page.content.auth_1')}
            </p>
            <div className={'block-primary'}>
              <ul className={'list-primary'}>{roles.map(roleItem)}</ul>
            </div>
          </Card>
        </Col>

        <Col span={24} xxl={16} className={'mb-5'}>
          <Card className={'card'}>
            <h2 className={'header-primary'}>
              {t('start_page.headers.settings_guide', 'Settings guide in Auth54')}
            </h2>
            <hr />
            <h3 className={'header-primary'}>
              {t('start_page.content.settings_guide')}
            </h3>
            <Row className={'d-flex'} gutter={[20, 20]}>
              <Col span={24} xl={10}>
                <div className={'block-primary h-100'}>
                  <span className={'badge-blue mb-3'}>{t('start_page.badges.way_1').toUpperCase()}</span>
                  <p className={'p-primary text-dark-blue mb-0'}>
                    {t('start_page.content.way_1.text_0')}
                    <br />
                    {t('start_page.content.way_1.text_1')}
                    {copyButton()}
                    {t('start_page.content.way_1.text_2')}
                  </p>
                </div>
              </Col>
              <Col span={24} xl={14}>
                <div className={'block-primary h-100'}>
                  <span className={'badge-blue mb-3'}>{t('start_page.badges.way_2').toUpperCase()}</span>
                  <p className={'p-primary text-dark-blue mb-0'}>
                    {t('start_page.content.way_2.text_0')}
                    <br />
                    {t('start_page.content.way_2.text_1')}
                    <br />
                    {t('start_page.content.way_2.text_2')}
                  </p>
                  <p className={'p-important mt-3'}>
                    <strong className={'strong'}>
                      {t('start_page.content.way_2.important.title')
                        .toUpperCase()}:{' '}
                    </strong>
                    {t('start_page.content.way_2.important.text')}
                  </p>
                </div>
              </Col>
            </Row>
            <hr />
            <p className={'p-primary mb-0'}>
              {t('start_page.content.settings_guide_end_0')}
              <br />
              {t('start_page.content.settings_guide_end_1')}
              {' '}
              <a href={`${wmsLink}/startpage`}>
                {t('start_page.content.instruction_link')}
              </a>.
            </p>
          </Card>
        </Col>

        <Col span={24} xxl={16} className={'mb-5'}>
          <Card className={'card'}>
            <h2 className={'header-primary'}>
              {t('start_page.headers.recommendations', 'Our recommendations')}
            </h2>
            <hr />
            <p className={'p-primary mb-5'}>
              {t('start_page.content.recommendations')}
            </p>
            <Timeline className={'pl-4'}>
              <Timeline.Item dot={stepDot(mdiAccountGroupOutline)}>
                <Row className={'ml-4'}>
                  <Col span={24}>
                    <h3 className={'header-primary'}>
                      {t('start_page.content.recommendations_steps.step_0.title')}
                    </h3>
                  </Col>
                  <Col>
                    <Timeline
                      direction={'vertical'}
                      className={'sub_timeline'}
                    >
                      <Timeline.Item>
                        <div className={'block-primary'}>
                          <p className={'p-primary text-dark-blue mb-0'}>
                            {t('start_page.content.recommendations_steps.step_0.sub_step_0')}
                          </p>
                        </div>
                      </Timeline.Item>
                      <Timeline.Item>
                        <div className={'block-primary'}>
                          <p className={'p-primary text-dark-blue mb-3'}>
                            {t('start_page.content.recommendations_steps.step_0.sub_step_1.title')}
                          </p>
                          <ul className={'list-secondary'}>
                            <li className={'text-dark-blue'}>
                              {t('start_page.content.recommendations_steps.step_0.sub_step_1.li_0')}
                            </li>
                            <li className={'text-dark-blue'}>
                              {t('start_page.content.recommendations_steps.step_0.sub_step_1.li_1')}
                            </li>
                            <li className={'text-dark-blue'}>
                              {t('start_page.content.recommendations_steps.step_0.sub_step_1.li_2')}
                            </li>
                            <li className={'text-dark-blue'}>
                              {t('start_page.content.recommendations_steps.step_0.sub_step_1.li_3')}
                            </li>
                          </ul>
                        </div>
                      </Timeline.Item>
                      <Timeline.Item>
                        <div className={'block-primary'}>
                          <p className={'p-primary text-dark-blue mb-0'}>
                            {t(
                              'start_page.content.recommendations_steps.step_0.sub_step_2')}
                          </p>
                        </div>
                      </Timeline.Item>
                    </Timeline>
                  </Col>
                </Row>
              </Timeline.Item>
              <Timeline.Item dot={stepDot(mdiAccountMultiplePlusOutline)}>
                <Row className={'ml-4'}>
                  <Col span={24}>
                    <h3 className={'header-primary'}>
                      {t('start_page.content.recommendations_steps.step_1.title')}
                    </h3>
                  </Col>
                  <Col>
                    <Timeline
                      direction={'vertical'}
                      className={'sub_timeline'}
                    >
                      <Timeline.Item>
                        <div className={'block-primary'}>
                          <p className={'p-primary text-dark-blue mb-0'}>
                            {t('start_page.content.recommendations_steps.step_1.sub_step_0')}
                          </p>
                        </div>
                      </Timeline.Item>
                      <Timeline.Item>
                        <div className={'block-primary'}>
                          <p className={'p-primary text-dark-blue mb-0'}>
                            {t('start_page.content.recommendations_steps.step_1.sub_step_1')}
                          </p>
                        </div>
                      </Timeline.Item>
                    </Timeline>
                  </Col>
                </Row>
                <hr />
                <p className={'p-primary mb-0'}>
                  {t('start_page.content.recommendations_end_0')}
                  <br />
                  {t('start_page.content.recommendations_end_1')}
                  {' '}
                  <a href={`${wmsLink}/startpage`}>{t('start_page.content.instruction_link')}</a>.
                </p>
              </Timeline.Item>
            </Timeline>
          </Card>
        </Col>
      </Row>
    </PageWrapper>
  );
};

export default StartPage;
