import React, { memo } from 'react';
import { Button } from 'antd';
import Icon from '@mdi/react';

const AntButtonWithMDI = memo(({
  className,
  disabled,
  type,
  pathForMdi,
  sizeMdi = 1,
  label,
  onClick,
  iconClassName,
  size,
}) => {
  // console.log('RENDER ANTBTN', label);
  return (
    <Button
      type={type}
      className={className}
      size={size}
      onClick={onClick}
      disabled={disabled}
      icon={pathForMdi && (
        <Icon
          className={iconClassName}
          path={pathForMdi}
          size={sizeMdi}
        />
      )}
    >
      {label}
    </Button>
  );
});

export default AntButtonWithMDI;
